export const USER_ROLES = {
  BUSINESS_USER: 'BusinessUser',
  INFLUENCER: 'Influencer',
  ENCHORER: 'Enchorer',
};
export const facebookId = '/Facebook/fa-facebook-f';
export const instagramId = '/Instagram/fa-instagram';
export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_AND_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;

export const LANDING_PAGE = {
  EXTERNAL: 'external',
  INTERNAL: 'internal',
};

export const ERRORS = {
  VERIFICATION_CODE_ALREADY_SENT: 'Verification code already sent',
};

export const headerMenu = {
  [USER_ROLES.BUSINESS_USER]: [
    {
      href: 'profile',
      label: 'profile',
    },
    {
      href: 'createCompany',
      label: 'company data',
    },
    // {
    //   href: 'profile',
    //   label: 'settings and preferences',
    // },
    {
      href: 'support',
      onClick: () => window.open('https://www.enchora.com/support', '_black'),
      label: 'support',
    },
  ],
  [USER_ROLES.ENCHORER]: [
    {
      href: 'profile',
      label: 'profile',
    },
  ],
};
export const headerLink = {
  [USER_ROLES.ENCHORER]: [
    {
      label: 'campaigns',
      href: 'campaignList',
      pathName: 'campaigns',
    },
    {
      label: 'cashoutList',
      href: 'cashoutList',
      pathName: 'cashout',
    },
    {
      label: 'influencerList',
      href: 'influencerList',
      pathName: 'influencers',
    },
    {
      label: 'businessUnitList',
      href: 'businessUnitList',
      pathName: 'businessUnits',
    },
  ],
  [USER_ROLES.BUSINESS_USER]: [
    {
      label: 'Home',
      href: 'home',
      pathName: 'home',
    },
    {
      label: 'my campaign',
      href: 'myCampaigns',
      pathName: 'campaigns',
    },
    // {
    //   label: 'Create company',
    //   href: 'createCompany',
    //   pathName: 'create-company',
    // },
  ],
};

export const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const AUTH_ERROR_INVALID_PASSWORD = 'auth/wrong-password';
export const AUTH_ERROR_INVALID_LINK = 'auth/invalid-action-code';
export const STORAGE_KEY_EMAIL_LINK_ADDRESS = 'emailForSignIn';
export const USER_NOT_EXIST_ERROR = 'user not exist';

export const CATALOGUES_TYPES = {
  LEVELS: 'careerLevel',
  SOCIALS: 'social',
  COUNTRIES: 'country',
  REGIONS: 'geographyV1V2',
  PROVINCES: 'geographyV2V3',
  CITIES: 'geographyV3V4',
  SPORTS: 'sport',
  EXPERTISE: 'expertise',
  GEOGRAPHY: 'geography',
  LANGUAGES: 'language',
  STORES: 'store',
  CURRENCY: 'currency',
  MISSION: 'mission',
  JOB: 'job',
  ACTIVE_CATALOGUE_ENTRY_SOCIAL: 'activeCatalogueEntrySocial',
};

export const CREATE_COMPANY_STEPS = {
  COMPANY_DATA: 'company-data',
  COMPANY_PROFILE: 'company-profile',
  BILLING_INFO: 'billing',
  PAYMENT_INFO: 'payment-information',
  CONTACTS: 'contacts',
  REFERENTS: 'referents',
};

//enchora, admin, business-user, influencer, platform, web, mobile, guest
export const ROLES = {
  ENCHORA: 'enchora',
  ADMIN: 'admin',
  BUSINESS_USER: 'business-user',
  INFLUENCER: 'influencer',
  PLATFORM: 'platform',
  WEB: 'web',
  MOBILE: 'mobile',
  GUEST: 'guest',
};

export const campaignStatus = {
  DRAFT: 'draft',
  PURCHASABLE: 'purchasable',
  ACTIVE: 'active',
  TO_BE_APPROVED: 'to-be-approved',
  APPROVED: 'approved',
  CLOSED: 'closed',
  NOT_APPROVED: 'not-approved',
  REJECTED: 'rejected',
  FINALIZED: 'finalized',
  DISMISSED: 'dismissed',
  BILLABLE: 'billable',
};

export const PROGRESS_BAR_COLOR = {
  [campaignStatus.ACTIVE]: '#8E24AA',
  [campaignStatus.APPROVED]: '#8E24AA',
  [campaignStatus.CLOSED]: '#FFC527',
  [campaignStatus.FINALIZED]: '#FFC527',
  [campaignStatus.DISMISSED]: '#FFC527',
};

export const campaignTypes = {
  PayPerClick: 'PayPerClick',
  PayPerContact: 'PayPerContact',
  PayPerSales: 'PayPerSales',
  PayPerPostEngagement: 'PayPerPostEngagement',
  // PayPerPostImpression: 'PayPerPostImpression',
};

//draft, complete, billable
export const BUSINESS_UNIT_STATUSES = {
  DRAFT: 'draft',
  COMPLETE: 'complete',
  BILLABLE: 'billable',
};

export const EDIT_STATUSES = {
  DRAFT: 'draft',
  COMPLETE: 'complete',
  DELETED: 'deleted',
};
// email, phone, email-phone
export const CONTACT_TYPES = {
  EMAIL: 'email',
  PHONE: 'phone',
  EMAIL_PHONE: 'email-phone',
};

export const FILE_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
};

export const STEP_SOCIAL = 'social';
export const STEP_BUDGET = 'budget';
export const STEP_CATEGORY_TIME_PLACE = 'category_time_place';

export const STEPS = {
  [campaignTypes.PayPerClick]: [STEP_SOCIAL, STEP_BUDGET, STEP_CATEGORY_TIME_PLACE],
  [campaignTypes.PayPerSales]: [STEP_SOCIAL, STEP_BUDGET, STEP_CATEGORY_TIME_PLACE],
  [campaignTypes.PayPerPostEngagement]: [STEP_SOCIAL, STEP_BUDGET, STEP_CATEGORY_TIME_PLACE],
  [campaignTypes.PayPerContact]: [STEP_SOCIAL, STEP_BUDGET, STEP_CATEGORY_TIME_PLACE],
};

export const BASE_URL_SITE = process.env.REACT_APP_APP_BASE_URL;

export const TOAST = {
  SUCCESS: {
    type: 'success',
    position: 'top-left',
  },
  ERROR: {
    type: 'error',
    position: 'top-left',
  },
  WARNING: {
    type: 'warning',
    position: 'top-left',
  },
};

export const defaultListObj = {
  count: 0,
  data: [],
  total: 0,
};

export const enchoraCoordinates = {
  lat: 45.4642,
  lng: 9.18854,
};

export const RESOURCES = {
  LANDING: 'landing',
};

export const ABUSE_TYPES = {
  INFLUENCER: 'influencer',
  CAMPAIGN: 'campaign',
};

export const FRAME_TYPE = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
};

export const DATE_TIME_UNITS = {
  [FRAME_TYPE.DAILY]: 'days',
  [FRAME_TYPE.WEEKLY]: 'weeks',
  [FRAME_TYPE.MONTHLY]: 'months',
  [FRAME_TYPE.QUARTERLY]: 'quarters',
  [FRAME_TYPE.YEARLY]: 'years',
};

export const DATE_TIME_UNITS_API_NEW = {
  day: FRAME_TYPE.DAILY,
  week: FRAME_TYPE.WEEKLY,
};

// draft, complete, pending, approved, finalized, dismissed
export const CASHOUT_STATUS = {
  DRAFT: 'draft',
  COMPLETE: 'complete',
  PENDING: 'pending',
  APPROVED: 'approved',
  FINALIZED: 'finalized',
  DISMISSED: 'dismissed',
};

export const CASHOUT_PROVIDER = {
  SATISPAY: 'satispay',
  PAYPAL: 'paypal',
  BANK_TRANSFER: 'bank-transfer',
};

// update, request, approve, reject, dismiss, finalize
export const CASHOUT_TRIGGER = {
  update: 'update',
  request: 'request',
  approve: 'approve',
  reject: 'reject',
  dismiss: 'dismiss',
  finalize: 'finalize',
};

export const carouselBreakpointResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
// not-yet, ongoing, done
export const MISSION_STATUS = {
  NOT_YET: 'not-yet',
  ON_GOING: 'ongoing',
  DONE: 'done',
};
