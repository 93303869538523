import axios from 'axios';
import { store } from '../app/store';
import { userSelectors } from '../features/userSlice';
import _ from 'lodash';
import jwt_decode from 'jwt-decode';
import { DateTime } from 'luxon';
import { logout, refreshToken } from './auth';
import { prefixUrl } from '../utils';
import { appSelectors, appSlice } from '../features/appSlice';
import { ENVIRONMENTS, ENVIRONMENTS_URLS } from '../app/App';
import { toast } from 'react-toastify';
import { translate } from '../i18n';
import { TOAST } from '../utils/const';

console.debug({ baseUrl: process.env.REACT_APP_API_URL });
axios.defaults.baseURL = process.env.REACT_APP_API_URL + prefixUrl();
// axios.defaults.baseURL = 'https://21ac-37-159-9-45.ngrok.io' + prefixUrl();
axios.defaults.headers['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  async (conf) => {
    let token;
    const state = store.getState();
    token = userSelectors.token(state);

    if (token) {
      const decodedToken = jwt_decode(token);
      // Is token expired
      if (decodedToken.exp < DateTime.fromJSDate(new Date()).toSeconds()) {
        console.log('isExpired');
        const refreshedToken = await refreshToken();
        if (refreshedToken) {
          token = refreshedToken;
        }
      }
    }
    conf.headers['Authorization'] = `Bearer ${token}`;

    const apiUrl = getApiUrl(state);
    if (apiUrl) {
      conf.baseURL = apiUrl;
    }

    console.log({ apiUrl });
    return conf;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log({ error });
    if (_.get(error, 'response.status') === 401) {
      await logout();
    }
    return Promise.reject(error);
  }
);

//MASTER AXIOS (interceptor non fa logout nel caso di 401)
const masterAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL + prefixUrl(),
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
});

masterAxios.interceptors.request.use(
  (conf) => {
    let token;
    const state = store.getState();
    token = userSelectors.token(state);

    conf.headers.Authorization = `Bearer ${token}`;

    const apiUrl = getApiUrl(state);
    if (apiUrl) {
      conf.baseURL = apiUrl;
    }

    console.log({ conf });
    return conf;
  },
  (error) => {
    return Promise.reject(error);
  }
);

masterAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

export { masterAxios };

export const axiosFacebook = axios.create({
  baseURL: 'https://graph.facebook.com/v13.0',
});

export const getApiUrl = (state) => {
  const debugApiUrl = appSelectors.appDebugApiUrl(state);
  if (debugApiUrl) {
    return debugApiUrl;
  }

  const environment = appSelectors.localEnvironment(state);
  return ENVIRONMENTS_URLS[environment];
};

export const getBaseUrl = (state) => {
  const environment = appSelectors.localEnvironment(state);
  switch (environment) {
    case ENVIRONMENTS.DEV:
      return 'dev-web.enchora.com';
    case ENVIRONMENTS.PRE_PROD:
      return 'pre-web.enchora.com';
    case ENVIRONMENTS.PROD:
      return 'web.enchora.com';
  }
};
