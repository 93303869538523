import React, { useMemo, useState } from 'react';
import _ from 'lodash';
// import './stripePay.scss';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { BaseModal, Button, Row } from '../../ui';
import StripeCheckout from 'react-stripe-checkout';
import stripe from '@stripe/stripe-js';
import './StripeCampaign.scss'
import { Card } from '@mui/material';
import {
  createOrderBankTransferCampaign,
  createOrderPaypalCampaign,
  createOrderStripeCampaign
} from '../../../api/payments';
import { useTranslation } from 'react-i18next';
import { getApiUrl, getBaseUrl } from '../../../api/axios';
import { useSelector } from 'react-redux';

// const STRIPE_PUBLISHABLE_KEY = 'pk_test_51KadLZDIBPJNNdXpzTzeo7rHj86pmrFHm1tcbAVyJGBDrhJlO4hE34VeXQP2UZJbHA7mG1UOkhoZ34Zj2SKxZPXT00WyJzg3LP'

const StripeCampaign = ({ customIds, total, disabled }) => {
  // const stripe = useStripe();
  // const elements = useElements();
  const { t } = useTranslation();
  const apiUrl = useSelector(getBaseUrl)

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log({elements, stripe})
    //
    // if (!stripe || !elements) {
    //   return;
    // }

    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: elements.getElement(CardElement),
    // });
    // console.log({error, paymentMethod})

    const stripeOrder = {
      "typeof": "PaymentIn",
      "provider": "stripe",
      "note": "",
      "correlationId": customIds,
      "req": {
        "mode": "payment",
        "success_url": `https://${apiUrl}/cart?payment=success`,
        "cancel_url": `https://${apiUrl}/cart?payment=reject`,
        "line_items": [
          {
            "quantity": customIds.length,
            "price_data": {
              "currency": "eur",
              "product_data": {
                "name": "Enchora",
                "description": "Acquista la tua campagna"
              },
              "unit_amount": Number((total*100).toFixed(0))
            }
          }
        ]
      }
    }

    const createdOrder = await createOrderBankTransferCampaign(stripeOrder)
    console.log({createdOrder})
    // setStripeUri(createdOrder.url)
    window.open(createdOrder.url, '_self')

    // const confirmPayment = await stripe.confirmCardPayment(
    //   clientSecret, {
    //     // payment__method: paymentMethod
    //     payment__method: {
    //       card: elements.getElement(CardElement)
    //     }
    //   }
    // )
  };

  const onGetToken = (stripeToken) => {
    console.log({stripeToken})
  }
  const onClose = (stripeToken) => {
    console.log({stripeToken})
  }

  return (
    <Button onClick={handleSubmit} disabled={disabled}>
      {t('pay with stripe')}
    </Button>
  )
}
//
// const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');
//
// const StripeCampaign = ({...props}) => (
//   <Elements stripe={stripePromise}>
//     <StripeForm {...props}/>
//   </Elements>
// );


export default StripeCampaign
