import React, { useEffect, useState } from 'react';
import './InfluencerListPage.scss';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Icon, InputText, Row } from '../../../components/ui';
import InputAdornment from '@mui/material/InputAdornment';
import { downloadCsv, getEnchorerInfluencerList } from '../../../api/enchorer';
import Table from '../../../components/layout/table/Table';
import _ from 'lodash';
import { fromMillisToFormat, normalizeCurrency, theme } from '../../../utils';
import TablePagination from '../../../components/layout/table/TablePagination';
import { influencerEntityFields } from '../../../utils/entityFields';
import { campaignStatus, CASHOUT_STATUS, CATALOGUES_TYPES, defaultListObj, MISSION_STATUS, TOAST } from '../../../utils/const';
import { ListSocial } from '../campaign/CampaignDetailPage';
import { AppRoutes, ENCHORER } from '../../../app/routers';
import SearchFilters from './SearchFilters';
import { appSelectors } from '../../../features/appSlice';
import { store } from '../../../app/store';
import { ConfirmModal } from '../../../components/layout/modals';
import { useModal } from '../../../utils/hook';
import axios from 'axios';
import { toast } from 'react-toastify';
import { translate } from '../../../i18n';

const InfluencerListPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [data, setData] = useState(defaultListObj);
  const [newEnchorerModal, setNewEnchorerModal] = useState({});
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(undefined);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const history = useHistory();
  const [filterValues, setFilterValues] = useState({
    levels: [],
    categories: [],
    cashoutStatus: [],
    newEnchorerStatus: undefined,
    fts: { text: undefined, caseSensitive: false },
  });
  const [sort, setSort] = useState({});

  const filters = React.useMemo(() => {
    const state = store.getState();
    return [
      {
        type: 'select',
        name: 'levels',
        label: 'infLevel',
        options: appSelectors.catalogueByType(CATALOGUES_TYPES.LEVELS)(state) || [],
        multiple: true,
        md: 3,
      },
      {
        type: 'select',
        name: 'categories',
        label: 'expertise',
        options: appSelectors.catalogueByType(CATALOGUES_TYPES.EXPERTISE)(state) || [],
        multiple: true,
        md: 3,
      },
      {
        type: 'select',
        name: 'cashoutStatus',
        label: 'cashout.status',
        options: _.values(CASHOUT_STATUS).map((v) => ({ label: t(v), _id: v })),
        multiple: true,
        md: 3,
      },
      {
        type: 'select',
        name: 'newEnchorerStatus',
        label: 'mission.newEnchorer',
        options: _.values(MISSION_STATUS).map((v) => ({ label: t(v), _id: v })),
        multiple: false,
        md: 3,
      },
    ];
  }, []);

  const onChangeText = (e) => {
    const val = e.target.value;
    setFilterValues((prevState) => ({ ...prevState, nickname: val }));
  };

  useEffect(() => {
    getInitialData();
  }, [filterValues]);

  const onDownloadCsv = async () => {
    setLoadingCsv(true);
    await downloadCsv('Influencer');
    setLoadingCsv(false);
  };

  const getInitialData = async () => {
    const influencerRes = await getEnchorerInfluencerList(0, { ...filterValues, sort });
    setData(influencerRes);
    setPage(0);
  };

  const handleChangePage = async (newPage) => {
    const influencerRes = await getEnchorerInfluencerList(newPage, { ...filterValues, sort });
    setData(influencerRes);
    setPage(newPage);
  };

  const goToInfluencerDetail = async (influencer) => {
    history.push(AppRoutes.influencerDetail(influencer._id));
  };

  const bodyCellComponent = {
    nickname: (row) => _.get(row, 'nickname', ' - '),
    'careerLevel.level.label': (row) => _.get(row, 'careerLevel.level.label', ' - '),
    'careerLevel.score': (row) => _.get(row, 'careerLevel.score', 0),
    categories: (row) => renderCategories(row),
    'created.ts': (row) => fromMillisToFormat(_.get(row, 'created.ts', 0)),
    'mission.newEnchorer': (row) => renderNewEnchorerMission(row),
    'performances.last': (row) => fromMillisToFormat(_.get(row, 'performances.last', 0)),
    'cashout.statuses': (row) => renderCashoutStatuses(row),
    'wallet.value': (row) => renderWallet(row),
    actions: (row) => renderActions(row),
  };

  const renderCashoutStatuses = (row) => {
    return (
      <div
        onClick={() => {
          history.push(AppRoutes.cashoutList(row._id));
        }}
        style={{ cursor: 'pointer' }}>
        {_.get(row, 'cashout.statuses', [])
          .map((s) => t(s))
          .toString()}
      </div>
    );
  };

  const renderNewEnchorerMission = (influencer) => {
    const status = _.get(influencer, 'newEnchorerMission.status', '');
    if (!status) {
      return ' - ';
    }
    return (
      <div onClick={() => onClickNewEnchorerMission(influencer._id)} style={{ cursor: status !== MISSION_STATUS.DONE && 'pointer' }}>
        {' '}
        {!status ? ' - ' : t(status)}{' '}
      </div>
    );
  };

  const onClickNewEnchorerMission = (infId) => {
    setNewEnchorerModal({
      onClose: () => setNewEnchorerModal({}),
      onConfirm: async () => {
        try {
          await axios.post(`private/missions/new-enchorer/${infId}/approve`);
          const influencerRes = await getEnchorerInfluencerList(page, { ...filterValues, sort });
          setData(influencerRes);
        } catch (e) {
          toast(translate(_.get(e, 'response.data.errors[0]', 'genericError') || 'genericError'), TOAST.ERROR);
        }
      },
      title: 'approve newEnchorerMission',
      subtitle: 'sure to approve new Enchorer mission',
      textConfirm: 'approve',
    });
  };

  const renderWallet = (influencer) => {
    return (
      <Row>
        {normalizeCurrency(_.get(influencer, 'wallet', {}))}
        {influencer && influencer?.wallet && influencer.wallet?.value && <Icon icon={'far fa-credit-card'} color={theme['primaryColor']} sx={{ paddingLeft: '0.3rem' }} />}
      </Row>
    );
  };

  const renderCategories = (influencer) => {
    const categories = _.get(influencer, 'categories', []);
    return (
      <Col>
        {categories?.map((cat) => (
          <span>{_.get(cat, 'label')}</span>
        ))}
      </Col>
    );
  };

  const renderActions = (row) => {
    const style = { width: '1rem', height: '1rem', padding: '0.3rem' };
    return (
      <Row justifyEnd flex spaceBetween>
        <IconButton className={'button'} onClick={() => goToInfluencerDetail(row)}>
          <Icon icon={'fas fa-pencil'} color={theme['primaryColor']} style={style} />
        </IconButton>
      </Row>
    );
  };

  const onRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    let sort = { asc: isAsc };

    if (property === 'nameSurname') {
      sort.fields = ['firstName', 'lastName'];
    } else {
      sort.fields = [property];
    }

    setSort(sort);

    const influencerRes = await getEnchorerInfluencerList(page, { ...filterValues, sort });
    setData(influencerRes);
  };

  const onChangeFilters = (newFilter) => {
    console.debug({ newFilter });
    setFilterValues((prevState) => ({ ...prevState, ...newFilter }));
  };

  return (
    <div id={'campaign-list'}>
      <ConfirmModal
        onConfirm={newEnchorerModal?.onConfirm}
        open={!_.isEmpty(newEnchorerModal)}
        onClose={newEnchorerModal?.onClose}
        title={newEnchorerModal?.title}
        subtitle={newEnchorerModal?.subtitle}
        textUndo={'undo'}
        textConfirm={newEnchorerModal?.textConfirm}
      />
      <Row alignCenter flex container md={12}>
        <Grid item md={3}>
          <Typography variant={'h1'}>{t('influencer List')}</Typography>
        </Grid>
        <Row item alignCenter md={9}>
          <Row flex>
            <InputText
              variant={'outlined'}
              fullWidth
              value={_.get(filterValues, 'nickname', '')}
              onChange={onChangeText}
              label={t('search')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon icon={'far fa-search'} color={'black'} />
                  </InputAdornment>
                ),
              }}
            />
          </Row>
          <IconButton className={'button-download'} onClick={onDownloadCsv}>
            <Icon loading={loadingCsv} icon={'far fa-file-csv'} color={'white'} sx={{ padding: '0.5rem' }} style={{ width: '1.5rem', height: '1.5rem' }} />
          </IconButton>
        </Row>
      </Row>
      <SearchFilters filters={filters} filterValues={filterValues} onChangeFilter={onChangeFilters} />
      <Table
        onRequestSort={onRequestSort}
        order={order}
        orderBy={orderBy}
        entityFields={influencerEntityFields}
        sortEnable={true}
        showCheckbox={false}
        data={data.data}
        bodyCellComponent={bodyCellComponent}>
        <TablePagination rows={data.data} setPage={handleChangePage} totalData={data.total} page={page} />
      </Table>
    </div>
  );
};

export default InfluencerListPage;
