import axios from "axios";
import { store } from '../app/store';
import { businessUnitSelectors } from '../features/businessUnitSlice';

export const uploadImage = async (image, path) => {
  try {
    const {clientSecret} = await fetch('https://api.stripe.com/v1/payment_intents', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paymentMethodType: 'card',
        currency: 'eur'
      })
    }).then(r => r.json())
  } catch (e) {
    console.log({errorUploadImage: e})
  }
}


export const createOrderPaypal = async (orderId, orderData) => {
  try {
    const { data: createOrderPaypal } = await axios.post(`/public/paypal/orders/${orderId}`, orderData);
    console.log({createOrderPaypal})
    return createOrderPaypal.data[0]
  } catch (e) {
    console.log({createOrderPaypalError: e})
  }
}

export const captureOrderPaypal = async (orderId) => {
  try {
    const { data: createOrderPaypal } = await axios.post(`/public/paypal/orders/${orderId}/capture`);
    console.log({createOrderPaypal})
  } catch (e) {
    console.log({createOrderPaypalError: e})
  }
}



export const createOrderPaypalCampaign = async (orderId, orderData) => {
  try {
    // const { data: createOrderPaypal } = await axios.post(`/public/paypal/orders/${orderId}`, orderData);
    const { data: createOrderPaypal } = await axios.post(`/public/paypal/payments`, orderData);
    console.log({createOrderPaypal})
    return createOrderPaypal.data[0]
  } catch (e) {
    console.log({createOrderPaypalError: e})
  }
}


export const createOrderStripeCampaign = async (orderData) => {
  try {
    const { data: createOrderPaypal } = await axios.post(`/public/stripe/payments`, orderData);
    console.log({createOrderPaypal})
    return createOrderPaypal.data[0]
  } catch (e) {
    console.log({createOrderPaypalError: e})
  }
}

export const createOrderBankTransferCampaign = async (orderData) => {
  try {
    const state = store.getState();
    const businessUnit = businessUnitSelectors.selected(state);
    let businessUnitId = businessUnit._id;

    const { data: createOrderPaypal } = await axios.post(`/private/business-units/${businessUnitId}/payments`, orderData);
    console.log({createOrderPaypal})
    return createOrderPaypal.data[0]
  } catch (e) {
    console.log({createOrderPaypalError: e})
  }
}

