import React from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import {
  captureOrderPaypal,
  createOrderBankTransferCampaign,
  createOrderPaypal,
  createOrderPaypalCampaign
} from '../../../api/payments';
import {REGEX} from '../../../utils/validations';
import {useSelector} from 'react-redux';
import {appSelectors} from '../../../features/appSlice';
import {ENVIRONMENTS} from '../../../app/App';


const PayPal = ({ disabled, orderId = '', onPayApprove, paypalId, total, payment_source, customId }) => {
  console.log({ orderId });
  const env = useSelector(appSelectors.localEnvironment)

  const initialOptions = {
    "client-id": "ARRQQ_AhW7HHhi_NVSJV55jTPvwLgeKSnuBuRZY0G1T1aOrF5rRn4Ih5WDkj6H_ZRVb3dBOGyZri_0r-", // PROD
    // "client-id": "AXYQDj5crRBXHOPUILxwqeOhyaUprVRKDNZAx5d937Ul1NGdZzkP2DeyL2scwaeRiIxTKK4Xj55zfK10", // DEV
    currency: "EUR",
    intent: "capture",
    // "data-client-token": "abc123xyz==",
    // "merchant-id": "5HHEB8M3KCRCY",
    // "data-merchant-id": "5HHEB8M3KCRCY",
    commit: true,
  };

  if (env === ENVIRONMENTS.DEV) {
    initialOptions['client-id'] = 'AXYQDj5crRBXHOPUILxwqeOhyaUprVRKDNZAx5d937Ul1NGdZzkP2DeyL2scwaeRiIxTKK4Xj55zfK10'
  }

  console.log({initialOptions})
  const onError = (err) => {
    console.log({ err });
  };

  const createOrder = async (data, actions) => {
    console.log('create');
    console.log(data, actions);


    const paypalOrder = {
      "intent": "CAPTURE",
      "purchase_units": [
        {
          // Passare id del fill form sale
          "customId": customId,
          "amount": {
            "currency_code": "EUR",
            "value": total.toFixed(2)
          },
          "payee": {
            // da sostituire il merchant con quello della campagna
            // "merchant_id": "5HHEB8M3KCRCY"
            // "email_address": "administration@enchora.com"
          },
        }
      ],
      // payment_source: payment_source
    }

    if (REGEX.EMAIL.test(paypalId)) {
      paypalOrder.purchase_units[0].payee.email_address = paypalId
      // paypalOrder.purchase_units[0].payee.email_address = 'administration@enchora.com'
    } else {
      paypalOrder.purchase_units[0].payee.merchant_id = paypalId

    }

    if (env === ENVIRONMENTS.DEV) {
      paypalOrder.purchase_units[0].payee.merchant_id = "5HHEB8M3KCRCY"
      paypalOrder.purchase_units[0].payee.email_address = undefined
    }
    console.log({paypalOrder})

    const order = {
      "typeof": "PaymentIn",
      "provider": "paypal",
      "note": "",
      "correlationId": customId,
      "req": paypalOrder
    }

    const createdOrder = await createOrderBankTransferCampaign(order)
    return createdOrder.id;
  }

  const onApprove = async (data, actions) => {
    console.log({data, actions});
    await onPayApprove(data)
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name;
      console.log({details})
      // alert(`Transaction completed by ${name}`);
    });
  }

  return (
    <PayPalScriptProvider  options={initialOptions}>
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
        disabled={false}
        // onInit={onInit}
        // onClick={onClick}
        // onError={onError}
        style={{
          shape: 'pill',
          label: 'pay',
          height: 40,
          layout: 'horizontal',
          tagline: false,
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPal;
