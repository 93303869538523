import React, { useEffect, useState, memo, useMemo } from 'react';
import './CampaignFilters.scss';
import { useTranslation } from 'react-i18next';
import { Col, Icon, InputDatePicker, InputSelect, InputText, Row } from '../../../components/ui';
import { useDispatch, useSelector } from 'react-redux';
import { campaignSelectors } from '../../../features/campaignSlice';
import { Box, Grow, IconButton } from '@mui/material';
import { setCampaignFilters } from '../../../features/campaignSlice';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { getEnchorerBusinessUnits } from '../../../api/enchorer';
import { campaignStatus, campaignTypes } from '../../../utils/const';
import { Animated } from 'react-animated-css';
import InputAdornment from '@mui/material/InputAdornment';

export const dateOptions = {
  last28Days: 'last28Days',
  thisMonth: 'thisMonth',
  last90Days: 'last90Days',
  lastMonth: 'lastMonth',
  thisYears: 'thisYears',
  custom: 'custom',
  allDate: undefined,
};

export const budgetOption = {
  allBudget: undefined,
  from1to100: { from: 1, to: 100 },
  from101to200: { from: 101, to: 200 },
  from201to400: { from: 201, to: 400 },
  from401to700: { from: 401, to: 700 },
  from701to1000: { from: 701, to: 1000 },
  from1001: { from: 1001 },
};

export const statusesOption = {
  allStatus: 'allStatus',
  ...campaignStatus,
};

export const campaignTypeOpt = {
  ...campaignTypes,
  allTypes: undefined,
};

const CampaignFilters = ({ filters, onChangeFilters }) => {
  const { t } = useTranslation();
  const campaignTypeOptions = _.map(_.keys(campaignTypeOpt), (o) => ({
    _id: o,
    label: t(`${o}.title`),
  }));
  const campaignStatusesOptions = _.map(_.values(campaignStatus), (o) => ({
    _id: o,
    label: t(o),
  }));
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const [search, setSearch] = useState(_.get(filters, 'name'));
  const [showFilters, setShowFilters] = useState(true);
  const [showPickerDates, setShowPickerDate] = useState(_.isEqual(_.get(filters, 'date'), dateOptions.custom));

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await getEnchorerBusinessUnits(t);
    setBusinessUnitOptions(res);
  };

  const inputStyle = {
    //backgroundColor: '#D7D7D7',
    color: 'black',
    width: '18rem',
    marginInline: '1rem',
    marginBottom: '0.5rem',
  };
  const onToggleShowFilters = () => {
    setShowFilters((prevState) => !prevState);
  };
  const onChangeDateFilters = (_id) => {
    console.log({ _id });
    let now = DateTime.now().toMillis();
    let fromDate;

    if (_id === dateOptions.last28Days) {
      fromDate = DateTime.now().minus({ days: 28 }).toMillis();
    }
    if (_id === dateOptions.last90Days) {
      fromDate = DateTime.now().minus({ days: 30 }).toMillis();
    }
    if (_id === dateOptions.thisMonth) {
      fromDate = DateTime.now().startOf('months').toMillis();
    }
    if (_id === dateOptions.lastMonth) {
      fromDate = DateTime.now().minus({ months: 1 }).startOf('months').toMillis();
    }
    if (_id === dateOptions.thisYears) {
      fromDate = DateTime.now().startOf('year').toMillis();
    }

    if (_id === dateOptions.custom) {
      setShowPickerDate(true);
    }

    if (_id === 'allDate') {
      fromDate = undefined;
      now = undefined;
    }

    const params = {
      date: _id,
      fromDate,
      toDate: now,
    };

    if (_id !== dateOptions.custom) {
      setShowPickerDate(false);
      onChangeFilters(params);
    }
  };

  const onChangeBusinessUnit = (value) => {
    onChangeFilters({ businessUnitId: value });
  };

  const onChangeCampaignType = (value) => {
    onChangeFilters({ typeof: value });
  };

  const onChangeFilterValue = (fieldName) => (e) => {
    const value = e.target.value;
    onChangeFilters({ [fieldName]: value });
  };

  const onChangeCustomDate = (value, dateField) => {
    onChangeFilters({ [dateField]: value.toMillis() });
  };

  const onChangeCampaignCode = (e) => {
    const value = e.target.value;
    setSearch(value);
    onChangeFilters({ name: value });
  };

  const onChangeCampaignStatus = (campaignStatus) => {
    onChangeFilters({ campaignStatus });
  };

  return (
    <Col>
      <Row id={'campaign-filters'} alignCenter spaceBetween>
        <IconButton className={'button'} onClick={onToggleShowFilters}>
          <Icon icon={showFilters ? 'far fa-times' : 'far fa-filter'} color={'white'} sx={{ padding: '0.5rem' }} style={{ width: '1.5rem', height: '1.5rem' }} />
        </IconButton>
        <Row flex spaceBetween container md={12}>
          <Col md={9}>
            <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={showFilters}>
              <InputSelect
                variant={'outlined'}
                label={t('date')}
                emitValue
                value={_.get(filters, 'date')}
                onChange={(value) => onChangeDateFilters(value)}
                options={_.map(_.keys(dateOptions), (o) => ({
                  _id: o,
                  label: t(o),
                }))}
                style={inputStyle}
              />
              {!_.isEmpty(businessUnitOptions) && (
                <InputSelect
                  variant={'outlined'}
                  label={t('businessUnit')}
                  emitValue
                  value={_.get(filters, 'businessUnitId')}
                  onChange={(value) => onChangeBusinessUnit(value)}
                  options={businessUnitOptions}
                  style={inputStyle}
                />
              )}
              <InputSelect
                variant={'outlined'}
                label={t('campaign type')}
                emitValue
                value={_.get(filters, 'typeof')}
                onChange={(value) => onChangeCampaignType(value)}
                options={campaignTypeOptions}
                style={inputStyle}
              />
              <InputText
                variant={'outlined'}
                label={t('minBudget')}
                value={_.get(filters, 'minBudget')}
                onChange={onChangeFilterValue('minBudget')}
                InputProps={{
                  inputProps: {
                    min: Number(0),
                  },
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                }}
                style={inputStyle}
                type={'number'}
              />
              <InputText
                variant={'outlined'}
                label={t('maxBudget')}
                value={_.get(filters, 'minBudget')}
                onChange={onChangeFilterValue('maxBudget')}
                InputProps={{
                  inputProps: {
                    min: Number(0),
                  },
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                }}
                style={inputStyle}
                type={'number'}
              />
              <InputSelect
                variant={'outlined'}
                label={t('campaignStatus')}
                emitValue
                value={_.get(filters, 'campaignStatus', [])}
                onChange={onChangeCampaignStatus}
                options={campaignStatusesOptions}
                multiple
                SelectProps={{
                  multiple: true,
                }}
                style={inputStyle}
              />
              <InputText variant={'outlined'} label={t('campaignCode')} emitValue value={search} onChange={onChangeCampaignCode} style={inputStyle} />
            </Animated>
          </Col>
          {/*<Col alignCenter justifyCenter>*/}
          {/*  <Animated animationIn="bounceInRight" animationOut="fadeOut" isVisible={showFilters}>*/}
          {/*    <InputSelect variant={'outlined'} label={t('sort')} emitValue onChange={(value) => onChangeCampaignSort(value)} options={campaignSortOption} style={inputStyle} />*/}
          {/*  </Animated>*/}
          {/*</Col>*/}
        </Row>
      </Row>
      {showPickerDates && (
        <Row flex container md={12}>
          <Col item md={3}>
            <InputDatePicker
              label={t('from')}
              value={DateTime.fromMillis(filters.fromDate).toJSDate()}
              handleChange={(newDate) => onChangeCustomDate(newDate, 'fromDate')}
              minDate={DateTime.fromObject({ year: 2020 })}
            />
          </Col>
          <Col item md={3}>
            <InputDatePicker
              label={t('to')}
              value={DateTime.fromMillis(filters.toDate).toJSDate()}
              handleChange={(newDate) => onChangeCustomDate(newDate, 'toDate')}
              minDate={DateTime.fromObject({ year: 2020 })}
            />
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default CampaignFilters;
