import React, { useEffect, useState } from 'react';
import './CashoutListPage.scss';
import { getEnchorerCashout, getEnchorerInfluencerList, getEnchorerInfluencerOptions, updateCashoutStatus } from '../../../api/enchorer';
import _ from 'lodash';
import { fromMillisToFormat, getParameterFromUrl, normalizeCurrency, theme } from '../../../utils';
import { BaseDialog, BaseModal, Button, Col, Icon, InputTextArea, Row } from '../../../components/ui';
import { Card, Grid, IconButton, Typography } from '@mui/material';
import Table from '../../../components/layout/table/Table';
import { CASHOUT_PROVIDER, CASHOUT_STATUS, CASHOUT_TRIGGER, CATALOGUES_TYPES, DATE_AND_TIME_FORMAT, defaultListObj, MISSION_STATUS } from '../../../utils/const';
import { useTranslation } from 'react-i18next';
import TablePagination from '../../../components/layout/table/TablePagination';
import { cashoutEntityFields } from '../../../utils/entityFields';
import { CashoutIdentityModal, ConfirmModal } from '../../../components/layout/modals';
import { getUrlImageFromPath } from '../../../api/upload';
import { store } from '../../../app/store';
import { appSelectors } from '../../../features/appSlice';
import SearchFilters from '../influencers/SearchFilters';
import { useHistory } from 'react-router-dom';

export const CASHOUT_MODES = {
  APPROVE: 'approve',
  REFUSE: 'refuse',
};

const OPEN_MODAL_TYPES = {
  INVOICE: 'INVOICE',
  PAYMENT_INFO: 'PAYMENT_INFO',
};

const CashoutListPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState(defaultListObj);
  const [filterValues, setFilterValues] = useState({
    influencers: [],
  });
  const [modal, setModal] = useState({
    visible: false,
    item: {},
  });
  const [openModal, setOpenModal] = useState({});
  const [rejectionNotes, setRejectionNotes] = useState('');
  const [modalCashoutStatus, setModalCashoutStatus] = useState({
    visible: false,
    item: {},
    mode: '',
  });
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const { infId } = getParameterFromUrl(history.location.search);
    onChangeFilters({ influencers: [infId] });
    getEnchorerInfluencerOptions().then((res) => {
      setFilters([
        {
          type: 'select',
          name: 'influencers',
          label: t('influencer'),
          options: [...res],
          multiple: true,
          clearable: true,
          md: 3,
        },
      ]);
    });
  }, [history.location.search]);

  useEffect(() => {
    getInitialData(filterValues);
  }, [filterValues]);

  const getInitialData = async (filter) => {
    const cashouts = await getEnchorerCashout(0, { ...filter });
    console.debug({ getInitialData: cashouts });
    setData(cashouts);
    setPage(0);
  };

  const handleChangePage = async (newPage) => {
    const cashouts = await getEnchorerCashout(newPage, { ...filterValues });
    console.debug({ getInitialData: cashouts });
    setData(cashouts);
    setPage(newPage);
  };

  const onChangeVisibleModal = (cashout = {}) => {
    setModal((prevState) => ({
      ...prevState,
      visible: !prevState.visible,
      item: cashout,
    }));
  };

  const bodyCellComponent = {
    actions: (row) => renderActions(row),
    amount: (row) => normalizeCurrency(row?.amount),
    cashoutStatus: (row) => t(row.cashoutStatus),
    infType: (row) => (_.get(row, 'cashoutInfo.professional') ? t('professional') : t('amateur')),
    electronicInvoicing: (row) => renderElectronicInvoicing(row),
    createdCashout: (row) => fromMillisToFormat(_.get(row, 'created.ts', 0), DATE_AND_TIME_FORMAT),
    paymentProvider: (row) => renderPaymentProvider(row),
    under16: (row) => (row?.cashoutInfo?.under16 ? t('yes') : t('no')),
  };

  const openInvoiceModal = async (row) => {
    const invoiceUri = _.get(row, 'cashoutInfo.invoice.uri');
    console.debug({ invoiceUri });
    if (!invoiceUri) {
      return;
    }
    const normUrl = await getUrlImageFromPath(invoiceUri);
    window.open(normUrl, '_blank');
  };

  const renderElectronicInvoicing = (row) => {
    const style = { width: '1rem', height: '1rem', padding: '0.3rem' };

    return (
      <div>
        <span>{row?.cashoutInfo?.electronicInvoice ? 'SI' : 'NO'}</span>
        <IconButton className={'button'}>
          <Icon icon={'fas fa-file'} color={'primary'} style={style} onClick={() => openInvoiceModal(row)} />
        </IconButton>
      </div>
    );
  };

  const renderPaymentProvider = (row) => {
    return (
      <span className={'cursor-pointer'} onClick={() => openModalPaymentInfo(row)}>
        {t(_.get(row, 'cashoutInfo.provider', ' - '))}
      </span>
    );
  };

  const openModalPaymentInfo = (row) => {
    setOpenModal({ ...row, type: OPEN_MODAL_TYPES.PAYMENT_INFO, title: 'paymentInfo' });
  };

  const onClosePaymentModal = () => {
    setOpenModal({});
  };

  const openIdentityModal = async (e, cashout) => {
    e.preventDefault();
    // onChangeVisibleModal(cashout);
    const identityID = _.get(cashout, 'cashoutInfo.identityID.source.uri');
    console.log({ identityID });
    const normUrl = await getUrlImageFromPath(identityID);

    window.open(normUrl, '_blank');
  };

  const onApprove = async () => {
    await updateCashoutStatus(modalCashoutStatus.item._id, CASHOUT_TRIGGER.approve, rejectionNotes);
    await handleChangePage(page);
  };

  const onRefuse = async () => {
    await updateCashoutStatus(modalCashoutStatus.item._id, CASHOUT_TRIGGER.reject, rejectionNotes);
    setRejectionNotes('');
    await handleChangePage(page);
  };

  const openApproveModal = (cashout) => {
    setModalCashoutStatus((prevState) => ({
      ...prevState,
      visible: true,
      item: cashout,
      mode: CASHOUT_MODES.APPROVE,
    }));
  };
  const openRefuseModal = (cashout) => {
    setModalCashoutStatus((prevState) => ({
      ...prevState,
      visible: true,
      item: cashout,
      mode: CASHOUT_MODES.REFUSE,
    }));
  };

  const onCloseStatusModal = () => {
    setModalCashoutStatus({ ...modalCashoutStatus, visible: false });
  };

  const renderActions = (row) => {
    const style = { width: '1rem', height: '1rem', padding: '0.3rem' };
    const showApproveButton = [CASHOUT_STATUS.PENDING].includes(row.cashoutStatus);
    const showRefuseButton = [CASHOUT_STATUS.PENDING].includes(row.cashoutStatus);
    return (
      <Row justifyEnd flex spaceBetween>
        {_.get(row, 'cashoutInfo.identityID.source.uri') && (
          <IconButton className={'button'}>
            <Icon icon={'fas fa-id-card'} color={theme['grey']} style={style} onClick={(e) => openIdentityModal(e, row)} />
          </IconButton>
        )}
        {showApproveButton && (
          <IconButton className={'button'}>
            <Icon icon={'fas fa-check'} color={'green'} style={style} onClick={() => openApproveModal(row)} />
          </IconButton>
        )}
        {showRefuseButton && (
          <IconButton className={'button'}>
            <Icon icon={'far fa-times'} color={'red'} style={style} onClick={() => openRefuseModal(row)} />
          </IconButton>
        )}
      </Row>
    );
  };

  const onChangeFilters = (newFilter) => {
    setFilterValues((prevState) => ({ ...prevState, ...newFilter }));
  };

  return (
    <div id="cashout-list">
      <CashoutIdentityModal open={modal.visible} onClose={onChangeVisibleModal} source={modal.item} />
      <ConfirmModal
        title={t(`cashout.${modalCashoutStatus.mode}`)}
        textConfirm={t(modalCashoutStatus.mode)}
        open={modalCashoutStatus.visible}
        onClose={onCloseStatusModal}
        onConfirm={modalCashoutStatus.mode === CASHOUT_MODES.REFUSE ? onRefuse : onApprove}>
        {modalCashoutStatus.mode === CASHOUT_MODES.REFUSE && (
          <InputTextArea
            style={{
              borderRadius: 16,
              marginTop: '-1rem',
              marginBottom: '1rem',
            }}
            rows="4"
            onChange={setRejectionNotes}
            value={rejectionNotes}
            placeholder={t('notes')}
            maxLength={200}
          />
        )}
      </ConfirmModal>
      <BaseModal open={!_.isEmpty(openModal)} style={{ height: '30rem', width: '40rem' }}>
        <PaymentModalContent openModal={openModal} onClose={onClosePaymentModal} />
      </BaseModal>
      <Grid item md={3} style={{ marginBottom: '0.5rem' }}>
        <Typography variant={'h1'}>{t('cashout.list')}</Typography>
      </Grid>
      <SearchFilters filters={filters} filterValues={filterValues} onChangeFilter={onChangeFilters} />
      <Table entityFields={cashoutEntityFields} data={data.data} bodyCellComponent={bodyCellComponent} showCheckbox={false}>
        <TablePagination rows={data.data} page={page} setPage={handleChangePage} totalData={data.total} />
      </Table>
    </div>
  );
};

export default CashoutListPage;

const PaymentModalContent = ({ openModal, onClose }) => {
  const { t } = useTranslation();
  console.debug({ openModal });
  const provider = _.get(openModal, 'cashoutInfo.provider');
  const cashoutInfo = _.get(openModal, 'cashoutInfo');

  useEffect(() => {
    if (openModal.type === OPEN_MODAL_TYPES.INVOICE) {
      // const invoiceUrl = getUrlImageFromPath(_.get(openModal, 'cashoutInfo.provider');)
    }
  }, [openModal]);

  return (
    <Card id={'modal-confirm'}>
      <Col style={{ marginBottom: '1rem' }}>
        <span className={'dialog-title'}>{t(openModal.title)}</span>
      </Col>
      {openModal.type === OPEN_MODAL_TYPES.PAYMENT_INFO && provider === CASHOUT_PROVIDER.SATISPAY && (
        <Col>
          <div>
            <span className={'dialog-label'}>{t('customerName')}:</span>
            <span className={'dialog-value'}>{_.get(cashoutInfo, 'customer.name')}</span>
          </div>
          <div>
            <span className={'dialog-label'}>{t('customerPhone')}:</span>
            <span className={'dialog-value'}>{_.get(cashoutInfo, 'customer.satispay')}</span>
          </div>
        </Col>
      )}
      {openModal.type === OPEN_MODAL_TYPES.PAYMENT_INFO && provider === CASHOUT_PROVIDER.BANK_TRANSFER && (
        <Col>
          <div>
            <span className={'dialog-label'}>{t('customerName')}:</span>
            <span className={'dialog-value'}>{_.get(cashoutInfo, 'customer.name')}</span>
          </div>
          <div>
            <span className={'dialog-label'}>{t('customerIban')}:</span>
            <span className={'dialog-value'}>{_.get(cashoutInfo, 'customer.iban')}</span>
          </div>
        </Col>
      )}
      {openModal.type === OPEN_MODAL_TYPES.PAYMENT_INFO && provider === CASHOUT_PROVIDER.PAYPAL && (
        <Col>
          <div>
            <span className={'dialog-label'}>{t('customerName')}:</span>
            <span className={'dialog-value'}>{_.get(cashoutInfo, 'customer.name')}</span>
          </div>
          <div>
            <span className={'dialog-label'}>{t('customerPaypalAccount')}:</span>
            <span className={'dialog-value'}>{_.get(cashoutInfo, 'customer.paypal.mail')}</span>
          </div>
        </Col>
      )}
      {openModal.type === OPEN_MODAL_TYPES.INVOICE && <Col></Col>}
      <Col style={{ marginTop: '1rem' }}>
        <Button onClick={onClose}>{t('close')}</Button>
      </Col>
    </Card>
  );
};
