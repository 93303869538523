import React, { useEffect, useMemo, useState } from 'react';
import { Link, Stack, Toolbar } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col } from '../../ui';
import './header.scss';
import { LogoBlack, LogoLongLight } from '../../../assets';
import { useTranslation } from 'react-i18next';
import { headerLink } from '../../../utils/const';
import { AppRoutes, PUBLIC } from '../../../app/routers';
import { setAppLoginModal } from '../../../features/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../../features/userSlice';
import _ from 'lodash';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import {
  assignCampaignToBusinessUnit, checkUserInvitationAndAcceptIt,
  getBusinessUnit, getBusinessUnitCampaigns,
  getBusinessUnitInvitations,
  getBusinessUnitList
} from '../../../api';
import Menu from './Menu';
import { UserDetail } from './UserDetail';
import { campaignSelectors, setCampaignSelected } from '../../../features/campaignSlice';
import { ConfirmModal } from '../modals';
import { isCurrentUserEnchorer } from '../../../api/enchorer';

export const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userToken = useSelector(userSelectors.token);
  const userRole = useSelector(userSelectors.role);
  const userBusinessUnits = useSelector(businessUnitSelectors.list);
  const businessUnitSelected = useSelector(businessUnitSelectors.selected);
  const campaignSelected = useSelector(campaignSelectors.selected);
  const [showMenu, setShowMenu] = useState(false);
  const [showConnectCampaignModal, setShowConnectCampaignModal] = useState(false);

  useEffect(() => {
    if (userToken) {
      getBusinessUnit();
      getBusinessUnitCampaigns();
      getBusinessUnitList();
      getBusinessUnitInvitations();
      checkUserInvitationAndAcceptIt();
      isCurrentUserEnchorer();
    }
  }, [userToken]);

  useEffect(() => {
    if (userBusinessUnits && !_.isEmpty(userBusinessUnits)) {
      getBusinessUnitInvitations();
    }
  }, [userBusinessUnits]);

  useEffect(() => {
    console.log({HEADERBU: businessUnitSelected, campaignSelected})
    if (!_.isEmpty(businessUnitSelected) && !location.pathname.includes('campaigns/new')) {
      if (campaignSelected && campaignSelected._id && !campaignSelected.businessUnit) {
        setShowConnectCampaignModal(true);
      }
    }
  }, [businessUnitSelected, campaignSelected]);

  const onConfirmConnectCampaign = async () => {
    await assignCampaignToBusinessUnit(campaignSelected._id);
    dispatch(setCampaignSelected({}));
  };
  const onDenyConnectCampaign = async () => {
    dispatch(setCampaignSelected({}));
    setShowConnectCampaignModal(false);
  };

  const showHeader = useMemo(() => {
    console.debug({ location });
    if (location.pathname.includes('/inf') && location.pathname !== '/enchorer/influencers/list' && !location.pathname.includes('/enchorer/influencers/detail')) {
      return false;
    }
    return !location.pathname.includes(PUBLIC) || location.pathname.includes('contact');
  }, [location.pathname]);

  const emptyHeader = useMemo(() => {
    return ['/login', '/verify-signup', '/complete-signup', AppRoutes.sendEmail()].includes(location.pathname) || (location.pathname.includes('contact') && location.pathname.includes('public'));
  }, [location.pathname]);

  const handleMenuClick = (menuItem) => {
    // console.log({ menuItem });

    if (menuItem?.onClick) {
      menuItem.onClick();
      setShowMenu(false);
      return;
    }

    if (menuItem.href === 'menu') {
      setShowMenu(!showMenu);
      return;
    }
    history.push(AppRoutes[menuItem.href]());
    setShowMenu(false);
  };

  const onPressLogin = () => {
    dispatch(setAppLoginModal(true));
    history.push(AppRoutes.login());
  };

  if (showHeader) {
    return (
      <div id={'header'}>
        <ConfirmModal
          open={showConnectCampaignModal}
          title={t('modal connect campaign to bu title')}
          subtitle={t('modal connect campaign to bu title description')}
          onClose={onDenyConnectCampaign}
          onConfirm={onConfirmConnectCampaign}
        />
        <Toolbar component={'header'} className={`e-header ${emptyHeader ? 'white' : 'black'}`}>
          <img src={LogoLongLight} alt={'logo'} className={'header-logo'} />
          <img src={LogoBlack} alt={'logo'} className={'header-logo-small'} />
          {!emptyHeader && (
            <Col flexEnd flex spaceBetween className={'header-col'}>
              {!userToken ? <Button onClick={onPressLogin} text={t('login')} /> : <UserDetail />}
              <Stack direction={'row'} justifyContent={'center'} spacing={{ xs: 2, sm: 2, md: 3 }} style={{ position: 'relative' }}>
                {headerLink[userRole].map((item, i) => (
                  <Link
                    key={i}
                    underline="none"
                    onClick={() => handleMenuClick(item)}
                    aria-disabled={item.disabled}
                    role="link"
                    color="inherit"
                    className="e-header__link"
                    aria-current={item.active || _.includes(location.pathname, item.pathName) ? 'page' : undefined}>
                    {t(item.label)}
                  </Link>
                ))}
                <Menu />
              </Stack>
            </Col>
          )}
        </Toolbar>
      </div>
    );
  } else {
    return <div />;
  }
};
