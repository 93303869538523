import { store } from '../app/store';
import { appSelectors } from '../features/appSlice';
import { Icon } from '../components/ui';
import { IconVk } from '../assets';
import React from 'react';
import Resizer from 'react-image-file-resizer';
import { campaignStatus, campaignStatus as campaignStatusConst, campaignTypes, EDIT_STATUSES, FRAME_TYPE, LANDING_PAGE } from './const';
import theme from './../app/theme.module.scss';
import { setUserCoordinates, userSelectors } from '../features/userSlice';
import { businessUnitSelectors } from '../features/businessUnitSlice';
import _ from 'lodash';
import { getSelectedCampaign, setAreas } from '../features/campaignSlice';
import { ContentState, convertFromHTML } from 'draft-js';
import { fromMillisToDate } from './date';
import { baseBusinessUnitContent } from '../pages/campaign/SocialConfiguratorPage';
import { PUBLIC } from '../app/routers';
import { COORDINATES_DEFAULT } from '../components/layout/campaign/StepCategoryMap';
import { getUrlImageFromPath } from '../api/upload';
import axios from 'axios';

export { theme };

export const prefixUrl = () => {
  const state = store.getState();
  const country = appSelectors.country(state);
  const version = appSelectors.version(state);

  return `/${version}/${country}`;
};

export const getParameterFromUrl = (url) => {
  console.log({ url });
  let regex = /[?&]([^=#]+)=([^&#]*)/g;
  let params = {};
  let match;
  while ((match = regex.exec(url))) {
    params[match[1]] = match[2];
    console.log(match[1], match[2]);
  }
  console.log({ params });
  return params;
};

export const renderSocialIcon = (social, showLabel) => {
  if (social.label === 'VKontakte') {
    return (
      <>
        <img src={IconVk} alt={social._id} style={{ height: 15, width: 15 }} /> {showLabel && social.label}
      </>
    );
  } else {
    return (
      <>
        <Icon icon={`fab ${social.values[1]}`} className={'primary'} /> {showLabel && social.label}
      </>
    );
  }
};

export const resizeFile = async (file, quality = 100) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1920,
      1920,
      'PNG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'blob'
    );
  });

export const b64toBlob = async (b64Data, contentType = '', sliceSize = 512) => {
  const base64 = await fetch(b64Data);
  const blob = await base64.blob();
  // console.log({ base64 });
  // console.log({ blob });
  return blob;
};

export const normalizeCurrency = (data) => {
  // console.debug({ normalizeCurrency: data });
  if (!data || !data?.value) {
    return ' - ';
  }

  const { value, currency } = data;
  if (value) {
    // return `${numberFormat(value)} ${currency.values[0]}`;
    return `${numberFormat(value)} €`;
  }
  return ' - ';
};
export const normalizePrice = (price) => {
  if (price || price === 0) {
    return `${Number(price).toFixed(2)} €`;
  }
  return ' - ';
};

export const getCurrentPosition = () => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  navigator.geolocation.getCurrentPosition(
    ({ coords }) => {
      console.log({ coords });
      store.dispatch(
        setUserCoordinates({
          lat: coords.latitude,
          lng: coords.longitude,
        })
      );
    },
    (error) => {
      store.dispatch(setUserCoordinates(COORDINATES_DEFAULT));
    },
    options
  );
};

export const isCampaignAvailableForPayment = ({ campaignStatus, editStatus }) => {
  const state = store.getState();
  const businessUnit = businessUnitSelectors.selected(state);

  const isAvailable =
    [campaignStatusConst.TO_BE_APPROVED, campaignStatusConst.BILLABLE].includes(campaignStatus) &&
    _.isEqual(editStatus, EDIT_STATUSES.COMPLETE) &&
    _.isEqual(businessUnit?.editStatus, EDIT_STATUSES.COMPLETE);

  console.log({ isAvailable });
  return isAvailable;
};

export const showIva = () => {
  try {
    const state = store.getState();
    const { billing } = businessUnitSelectors.selected(state);

    if ((billing && billing.hasIVA) || !billing) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log({ errorIva: e });
    return true;
  }
};

export const isAdminOfBusinessUnit = () => {
  try {
    const state = store.getState();
    const userData = userSelectors.detail(state);
    const businessUnit = businessUnitSelectors.selected(state);
    const isAdmin = businessUnit.businessUsers.some((bu) => bu.role === 'admin' && bu._id === userData._id);
    console.log({ isAdmin });
    return isAdmin;
  } catch (e) {
    console.log({ e });
    return false;
  }
};

export const normalizeListCampaigns = (list) => {
  return _.cloneDeepWith(_.map(list, (campaign) => getSelectedCampaign(campaign)));
};

export const normalizeCampaign = (campaign) => {
  return _.cloneDeepWith(getSelectedCampaign(campaign));
};

export const editorContent = (value) => {
  console.log({ value });
  const blocksFromHTML = convertFromHTML(value);
  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

  return state;
};

export const getCartTotal = (campaigns = []) => {
  if (!campaigns.length) {
    return 0;
  }
  return _.reduce(
    campaigns,
    (sum, campaign) => {
      return sum + _.get(campaign, 'budget.total.value');
    },
    0
  );
};

export const showProgressBar = (campaign) => {
  return ![campaignStatus.DRAFT, campaignStatus.TO_BE_APPROVED].includes(campaign.campaignStatus);
};

export * from './date';

export const getTimeUnitFromFilters = (dateFilter) => {
  if (!dateFilter || _.isEmpty(dateFilter)) {
    return { apiUnit: FRAME_TYPE.DAILY, unit: 'days' };
  }

  const from = fromMillisToDate(dateFilter?.$gt);
  const to = fromMillisToDate(dateFilter?.$lte);

  const days = _.ceil(to.diff(from, 'days').toObject().days);
  const weeks = _.ceil(to.diff(from, 'weeks').toObject().weeks);
  const months = _.ceil(to.diff(from, 'months').toObject().months);

  if (days <= 14) {
    return { apiUnit: FRAME_TYPE.DAILY, unit: 'days' };
  }
  if (days > 14 && weeks <= 12) {
    return { apiUnit: FRAME_TYPE.WEEKLY, unit: 'weeks' };
  }
  if (weeks > 12 && months <= 12) {
    return { apiUnit: FRAME_TYPE.MONTHLY, unit: 'months' };
  }
  if (months > 12) {
    return { apiUnit: FRAME_TYPE.YEARLY, unit: 'years' };
  }
};

export const isActiveFrom = (campaign) => {
  const duration = _.get(campaign, 'duration.duration');
  const endDate = fromMillisToDate(_.get(campaign, 'duration.end.ts'));

  if (_.isEmpty(campaign) || !endDate) {
    return 0;
  }

  const daysToEnd = endDate.diffNow('days').toObject().days;

  return duration - _.round(daysToEnd);
};

export const getFormattedCampaignDataToPatch = (values, selectedCampaign, availableSocial, campaignType, product) => {
  console.log({ product, selectedCampaign });
  const businessUnitContent = {
    ...baseBusinessUnitContent,
    ...selectedCampaign.contents.businessUnitContent,
  };

  let returnOby = {
    ...selectedCampaign,
    brief: values.brief,
    campaignStatus: 'draft',
    contents: {
      influencerContent: {
        info: values.info,
        socials: _.filter(availableSocial, (social) => values.socialSelected.includes(social._id)),
        images: selectedCampaign.contents.influencerContent.images || [],
        video: selectedCampaign.contents.influencerContent.video,
        hasCustomContents: values.hasCustomContents,
        hasCustomSocials: values.hasCustomSocials,
        instruction: selectedCampaign.contents.influencerContent.instruction,
      },
      businessUnitContent: {
        ...businessUnitContent,
      },
    },
  };

  if (campaignType === campaignTypes.PayPerClick) {
    returnOby = {
      ...returnOby,
      advice: {
        ...selectedCampaign.advice,
        external: values.landingPage === LANDING_PAGE.EXTERNAL,
        url: {
          mimeType: 'text/uri-list',
          uri: values.link,
        },
      },
    };
  }

  if (campaignType === campaignTypes.PayPerSales) {
    returnOby = {
      ...returnOby,
      paypalId: values.paypalId,
      product: {
        ...selectedCampaign.product,
        ...product,
      },
      advice: {
        url: {
          mimeType: 'text/uri-list',
          uri: `${process.env.REACT_APP_APP_BASE_URL}/${PUBLIC}/campaigns/product`,
        },
      },
    };
  }

  if (campaignType === campaignTypes.PayPerContact) {
    returnOby = {
      ...returnOby,
      contactType: values.contactType,
      advice: {
        mimeType: 'text/uri-list',
        uri: `${process.env.REACT_APP_APP_BASE_URL}/${PUBLIC}/campaigns/contactForm`,
      },
    };
  }
  console.debug({ returnOby });

  return _.cloneDeepWith(returnOby);
};

export const getCampaignTypeConfig = (campaignType) => {
  const state = store.getState();
  const appConfig = appSelectors.config(state);

  if (campaignType === campaignTypes.PayPerClick) {
    return _.get(appConfig, 'campaign.x-click');
  }

  if (campaignType === campaignTypes.PayPerSales) {
    return _.get(appConfig, 'campaign.x-sales');
  }

  if (campaignType === campaignTypes.PayPerPostEngagement) {
    return _.get(appConfig, 'campaign.x-post-engagement');
  }

  if (campaignType === campaignTypes.PayPerContact) {
    return _.get(appConfig, 'campaign.x-contact');
  }
};

export const calculateTotalCostVat = (campaignType, values, taxPercentage, totalCostUnique) => {
  // if(campaignType === campaignTypes.PayPerClick) {
  //   return totalCostUnique * Number(values.quantity) * taxPercentage
  // }
  return totalCostUnique * Number(values.quantity) * taxPercentage;
};

export const calculatedDisplayedBudget = (campaignType, values, taxPercentage, totalCostUnique, product) => {
  const campaignConfig = getCampaignTypeConfig(campaignType);
  const percentageOnSale = Number(_.get(campaignConfig, 'percentageOnSale', 0));

  if (campaignType === campaignTypes.PayPerClick) {
    return totalCostUnique * Number(values.quantity);
  }

  //budget di campagna = pezzi messi in vendita * (costo per vendita + extra tip + percentuale di vendita * prezzo oggetto).
  if (campaignType === campaignTypes.PayPerSales) {
    return totalCostUnique * Number(values.quantity);
    // const productPrice = Number(_.get(product, 'price.value', 0)).toFixed(2);
    // console.debug({
    //   productPrice: Number(productPrice),
    //   quantity: Number(values.quantity),
    //   totalCostUnique,
    //   percentageOnSale,
    // });
    // const priceUnit = totalCostUnique + percentageOnSale * Number(productPrice);
    // console.debug({ priceUnit });
    // return Number(values.quantity) * priceUnit;
  }

  if (campaignType === campaignTypes.PayPerPostEngagement) {
    return totalCostUnique * Number(values.quantity);
  }

  if (campaignType === campaignTypes.PayPerContact) {
    return totalCostUnique * Number(values.quantity);
  }
};

export const normalizeFilters = (filters) => {
  return _.omitBy(_.omitBy(_.omitBy(filters, _.isEmpty), _.isUndefined), _.isNull);
};

export const openEnchoraPrivacy = () => {
  window.open('https://docs.enchora.com/policies/it/220422_Enchora_Privacy_Policy.pdf', '_blank');
};

export const openEnchoraCookies = () => {
  window.open('https://docs.enchora.com/policies/it/220422_Enchora_Cookie_policy.pdf', '_blank');
};

export const openEnchoraTermsAndConditions = () => {
  window.open('https://docs.enchora.com/policies/it/220422_Enchora_Termini_e_condizioni_Brand.pdf', '_blank');
};

export const setAreasFromGeoJson = (campaign) => {
  const geoJsonAreas = _.get(campaign, 'geojson.geometries', []);
  console.debug({ geoJsonAreas });

  if (_.isEmpty(geoJsonAreas)) {
    getCurrentPosition();
    return [];
  }

  const reduced = _.reduce(
    geoJsonAreas,
    (result, acc) => {
      // console.debug({ result, acc });
      const randomColor = `rgb(${getRandomNumber()},${getRandomNumber()},${getRandomNumber()})`;
      const area = {
        color: randomColor,
        id: Math.random().toFixed(4).toString(),
        path: _.map(_.slice(acc.coordinates[0], 0, acc.coordinates[0].length - 1), (coordinate, index) => {
          console.debug({ coordinate });
          if (index === 0) {
            store.dispatch(
              setUserCoordinates({
                lat: coordinate[1],
                lng: coordinate[0],
              })
            );
          }
          return {
            lat: coordinate[1],
            lng: coordinate[0],
          };
        }),
      };
      return [...result, area];
    },
    []
  );
  // console.debug({ reduced });
  store.dispatch(setAreas(reduced));
  return reduced;
};

export const getRandomNumber = () => {
  return ((Math.random() * 1000) % 256).toFixed(0);
};

export const numberFormat = (number) => {
  if (!number) {
    return 0;
  }
  return Number(number).toFixed(2);
};

export const getImages = async (images) => {
  const promises = images.map((image) => getUrlImageFromPath(image.uri));
  const results = await Promise.all(promises);
  console.debug({ results });
  return _.map(images, (image, i) => {
    return {
      ...image,
      uri: results[i],
    };
  });
};

export const deleteAsset = async (asset) => {
  const newUri = await getUrlImageFromPath(asset.uri);
  try {
    console.debug({ newUri });
    await axios.delete(`private/storages?uri=${newUri}`);
  } catch (e) {
    console.debug({ e });
  }
};

export const filterCounters = (filters) => {
  return _.size(_.keys(_.omitBy(_.omitBy(_.omitBy(filters, _.isUndefined), _.isEmpty), _.isNull)));
};
