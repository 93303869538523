import React from 'react';
import { Row, Col, InputText, InputSelect, Icon } from '../../../components/ui';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';

const inputStyle = {
  alignSelf: 'flex-start',
  width: '100%',
};

const SearchFilters = ({ filters, filterValues, onChangeFilter }) => {
  const { t } = useTranslation();
  console.debug({ filters });

  const onChangeFilterValue = (fieldName) => (e) => {
    console.debug('onChange');
    const value = e.target.value;
    const newFilter = { [fieldName]: value };
    console.debug({ newFilter });
    onChangeFilter(newFilter);
  };

  const onChangeSelectValue = (fieldName) => (e) => {
    const newFilter = { [fieldName]: e };
    console.debug({ newFilter });
    onChangeFilter(newFilter);
  };

  return (
    <Row md={12} sm={12} xs={12} style={{ marginTop: '1rem' }} container rowSpacing={2} columnSpacing={2}>
      {filters.map((filter) => {
        if (filter.type === 'string') {
          return (
            <Col item md={filter.md || 3} key={filter.name}>
              <InputText
                InputProps={{
                  endAdornment: !!_.get(filterValues, `${filter.name}`, '') ? (
                    <InputAdornment position="end" sx={{ mr: '1.5rem', cursor: 'pointer' }} onClick={(e) => onChangeFilterValue(filter.name)(e)}>
                      <Icon icon={'far fa-times'} color={'black'} />
                    </InputAdornment>
                  ) : null,
                }}
                variant={'outlined'}
                label={t(filter.label)}
                value={_.get(filterValues, `${filter.name}`, '')}
                onChange={onChangeFilterValue(filter.name)}
                style={inputStyle}
              />
            </Col>
          );
        }
        if (filter.type === 'select') {
          console.debug({ filterSelect: filter?.options });
          return (
            <Col item md={filter.md || 3} key={filter.name}>
              <InputSelect
                variant={'outlined'}
                label={t(filter.label)}
                multiple={filter.multiple}
                optionValue={filter?.optionValue || '_id'}
                optionLabel={filter?.optionLabel || 'label'}
                emitValue
                SelectProps={{
                  multiple: filter.multiple,
                  clearable: true,
                  endAdornment:
                    (filter.multiple && filterValues[filter.name].length > 0) || (!filter.multiple && !!filterValues[filter.name]) ? (
                      <InputAdornment position="end" sx={{ mr: '1.5rem', cursor: 'pointer' }} onClick={() => onChangeSelectValue(filter.name)(filter.multiple ? [] : undefined)}>
                        <Icon icon={'far fa-times'} color={'black'} />
                      </InputAdornment>
                    ) : null,
                }}
                value={_.get(filterValues, `${filter.name}`, `${filter.multiple ? [] : ''}`)}
                onChange={onChangeSelectValue(filter.name)}
                options={filter.options}
                style={inputStyle}
              />
            </Col>
          );
        }
        return <div />;
      })}
    </Row>
  );
};

export default SearchFilters;
