import axios from 'axios';
import { store } from '../app/store';
import { userSelectors } from '../features/userSlice';
import { toast } from 'react-toastify';
import { translate } from '../i18n';
import { campaignTypes, CATALOGUES_TYPES, RESOURCES, TOAST } from '../utils/const';
import _ from 'lodash';
import { appSelectors } from '../features/appSlice';
import { campaignSelectors, editCampaignSelected, setCampaignSelected } from '../features/campaignSlice';
import { getCatalogue } from './catalogue';
import { uploadCampaignImage, uploadImage } from './upload';
import { businessUnitSelectors } from '../features/businessUnitSlice';
import { normalizeCampaign } from '../utils';
import { getBusinessUnitCampaigns } from './businessUnit';
import { PUBLIC } from '../app/routers';

export const getCampaigns = (id) => {
  return axios.get(`/public/campaigns/${id}`);
};

const baseApi = () => {
  const state = store.getState();
  const isLogged = userSelectors.token(state);
  const selectedBu = businessUnitSelectors.selected(state);

  const api = isLogged && !_.isEmpty(selectedBu) ? 'private' : 'public';

  return api;
};

export const createCampaign = async (type) => {
  const campaignData = {
    campaignType: type,
  };

  try {
    const { data } = await axios.post(`/public/campaigns`, campaignData);
    console.log({ data });
    store.dispatch(setCampaignSelected(_.first(data.data)));
    return _.first(data.data);
  } catch (e) {
    console.log({ e });
  }
};

export const assignCampaignToBusinessUnit = async (campaignId) => {
  const state = store.getState();
  const userToken = userSelectors.token(state);
  const { _id } = businessUnitSelectors.selected(state);

  if (!_id || !userToken) {
    console.log('assignCampaignToBusinessUnitError');
    return;
  }

  try {
    const { data } = await axios.post(`private/business-units/${_id}/campaigns/${campaignId}`);
    console.log({ data });
  } catch (e) {
    console.log({ assignCampaignToBusinessUnitError: e });
  }
};

const uploadImages = async (cmpId, path) => {
  const state = store.getState();
  const campaignData = campaignSelectors.selected(state);
  let imageUploaded = [],
    imageAlreadyUploaded = [];

  const imageToUpload = _.get(campaignData, `contents.${path}.images`, []).filter((image) => image.toUpload);
  if (imageToUpload.length) {
    const imageToUploadAsync = imageToUpload.map((image) => uploadImage(image.uri, `campaign/${cmpId}/image/${(Math.random() * 10000).toFixed(0)}`));
    imageUploaded = await Promise.all(imageToUploadAsync);
    imageAlreadyUploaded = _.get(campaignData, `contents.${path}.images`, []).filter((image) => !image.toUpload);
  }

  return {
    imageUploaded,
    imageAlreadyUploaded,
  };
};

const uploadVideo = async (cmpId, path) => {
  const state = store.getState();
  const campaignData = campaignSelectors.selected(state);
  let videoUploaded;
  const videoToUpload = _.get(campaignData, `contents.${path}.video`, {});
  if (videoToUpload.toUpload) {
    videoUploaded = await uploadImage(videoToUpload.uri, `campaign/${cmpId}/video`, 'video');
    campaignData.contents.influencerContent.video = videoUploaded;
    console.log({ videoUploaded });
  }

  return videoToUpload;
};

export const patchCampaign = async (id, campaignData) => {
  const state = store.getState();
  const cmp = campaignData || campaignSelectors.selected(state);

  try {
    // console.log({ campaignDataPatch: campaignData });
    const imageToUpload = _.get(campaignData, 'contents.influencerContent.images', []).filter((image) => image.toUpload);
    if (imageToUpload.length) {
      const imageToUploadAsync = imageToUpload.map((image) => uploadImage(image.uri, `campaign/${id}/image/${(Math.random() * 10000).toFixed(0)}`));
      // console.log({ imageToUpload });
      const imageUploaded = await Promise.all(imageToUploadAsync);
      const imageAlreadyUploaded = _.get(campaignData, 'contents.influencerContent.images', []).filter((image) => !image.toUpload);
      campaignData.contents.influencerContent.images = [...imageUploaded.filter((iu) => !!iu), ...imageAlreadyUploaded];
      // console.log({ imageUploaded });
    }

    const videoToUpload = _.get(campaignData, 'contents.influencerContent.video', {});
    let videoUploaded;
    if (videoToUpload.toUpload) {
      videoUploaded = await uploadImage(videoToUpload.uri, `campaign/${id}/video`, 'video');
      campaignData.contents.influencerContent.video = videoUploaded;
      // console.log({ videoUploaded });
    }

    const instructionToUpload = _.get(campaignData, 'contents.influencerContent.instruction', {});
    let instructionUploaded;
    if (instructionToUpload.toUpload) {
      instructionUploaded = await uploadImage(instructionToUpload.uri, `campaign/${id}/instruction`, 'video');
      campaignData.contents.influencerContent.instruction = instructionUploaded;
      console.log({ instructionUploaded });
    }

    const businessUnitImagesToUpload = _.get(cmp, 'contents.businessUnitContent.images', []).filter((image) => image.toUpload);
    // console.log({ businessUnitImagesToUpload });
    if (businessUnitImagesToUpload.length) {
      const businessUnitToUploadAsync = businessUnitImagesToUpload.map((image) => uploadImage(image.uri, `campaign/${id}/image/${(Math.random() * 10000).toFixed(0)}`));
      // console.log({ businessUnitToUploadAsync });
      const imageUploaded = await Promise.all(businessUnitToUploadAsync);
      const imageAlreadyUploaded = _.get(cmp, 'contents.businessUnitContent.images', []).filter((image) => !image.toUpload);
      console.log({ imageUploaded });
      campaignData.contents.businessUnitContent.images = [...imageUploaded, ...imageAlreadyUploaded];
    }

    const businessUnitVideoToUpload = _.get(cmp, 'contents.businessUnitContent.video', {});
    let businessUnitVideoUploaded;
    if (businessUnitVideoToUpload.toUpload) {
      businessUnitVideoUploaded = await uploadImage(videoToUpload.uri, `campaign/${id}/video`, 'video');
      campaignData.contents.businessUnitContent.video = businessUnitVideoUploaded;
      console.log({ businessUnitVideoUploaded });
    }

    if (campaignData.polygons) {
      campaignData.geojson = getGeoJsonFromPolygons(campaignData.polygons);
      campaignData.polygons = undefined;
    }

    if (cmp.typeof === campaignTypes.PayPerSales) {
      const updatedProduct = await uploadVideoAndImagePayPerSales(cmp);
      campaignData.product = _.cloneDeepWith(updatedProduct);
    }

    const omittedData = _.omitBy(campaignData, _.isEmpty);
    console.log({ campaignDataPatchEdited: omittedData });
    const { data } = await axios.patch(`${baseApi()}/campaigns/${id}`, omittedData);
    console.debug({ campaignData: data.data });
    store.dispatch(setCampaignSelected(_.first(data.data)));
    return _.first(data.data);
  } catch (e) {
    console.log({ e });
  }
};

const uploadVideoAndImagePayPerSales = async (cmp) => {
  console.log('uploadVideoAndImagePayPerSales');
  const state = store.getState();
  const cmpProduct = cmp.product;
  const productImages = _.get(cmp, 'product.images', []);
  const productVideos = _.get(cmp, 'product.video', []);

  let imagesToUpload = productImages.filter((image) => image.toUpload);
  let videosToUpload = productVideos.filter((video) => video.toUpload);

  const imgToUploadAsync = imagesToUpload.map((image) => uploadImage(image.uri, `campaign/${cmp._id}/image/${(Math.random() * 10000).toFixed(0)}`));
  const videoToUploadAsync = videosToUpload.map((image) => uploadImage(image.uri, `campaign/${cmp._id}/image/${(Math.random() * 10000).toFixed(0)}`, 'video'));

  const uploadImageResult = await Promise.all(imgToUploadAsync);
  const uploadVideoResult = await Promise.all(videoToUploadAsync);

  const product = {
    ...cmpProduct,
    images: [...(uploadImageResult || []), ...productImages.filter((image) => !image.toUpload)],
    video: [...(uploadVideoResult || []), ...productVideos.filter((video) => !video.toUpload)],
  };

  return { ...product };
};

export const deleteCampaign = async (campaignId) => {
  try {
    await axios.delete(`${baseApi()}/campaigns/${campaignId}`);
    toast(translate('campaign deleted success'), TOAST.SUCCESS);
    await getBusinessUnitCampaigns();
  } catch (e) {
    toast(translate('campaign deleted error'), TOAST.ERROR);
    console.log({ e });
  }
};

export const getCurrency = async () => {
  const items = await getCatalogue(CATALOGUES_TYPES.CURRENCY);
  const appropriateCurrency = _.find(items, (item) => _.isEqual(item._id, '/EUR/Euro'));
  return appropriateCurrency;
};

export const checkPromoCode = async (code, cmpId) => {
  // console.log({ code });
  const state = store.getState();
  const { _id: businessUnitId } = businessUnitSelectors.selected(state);
  try {
    const { data } = await axios.put(`/private/business-units/${businessUnitId}/campaigns/${cmpId}/promos/${code}`);
    // console.log({ data });
    store.dispatch(setCampaignSelected(data.data[0]));
    toast(translate('check promo code valid'), TOAST.SUCCESS);
    return data.data[0].budget.promo;
  } catch (e) {
    // console.log({ errorAddPromo: e });
    const error = JSON.parse(_.get(e, 'response.data.errors[0]', ''));
    const errorCode = error.errorCode;
    const errorValue = error.value;
    // console.log({ error, errorCode, errorValue });

    toast(translate(`promo error code ${errorCode}`, { value: errorValue }), TOAST.ERROR);
  }
};

export const deletePromo = async (promoId, cmpId) => {
  const state = store.getState();
  const { _id: businessUnitId } = businessUnitSelectors.selected(state);
  try {
    const { data } = await axios.delete(`/private/business-units/${businessUnitId}/campaigns/${cmpId}/promos/${promoId}`);
    console.log({ data });
    store.dispatch(setCampaignSelected(data.data[0]));
    toast(translate('promo removed success'), TOAST.SUCCESS);
  } catch (e) {
    toast(translate('error delete promo code'), TOAST.ERROR);
  }
};

export const getCampaignPrivate = async (id) => {
  try {
    const { data } = await axios.get(`private/campaigns/${id}`);
    return _.first(data.data);
  } catch (e) {
    return null;
  }
};

export const getCampaignPublic = async (id) => {
  try {
    const { data } = await axios.get(`public/campaigns/${id}`);
    return _.first(data.data);
  } catch (e) {
    return null;
  }
};
export const getCampaignDetail = async (campaignId, dispatch = true) => {
  const result = await Promise.all([getCampaignPublic(campaignId), getCampaignPrivate(campaignId)]);
  const validCampaign = _.last(_.compact(result));
  console.log({ result });
  console.log({ validCampaign });
  dispatch && store.dispatch(setCampaignSelected(validCampaign));
  return _.cloneDeepWith(validCampaign);
};

export const deletePublicCampaign = async (campaignId) => {
  try {
    const { data } = await axios.delete(`public/campaigns/${campaignId}`);
  } catch (e) {
    console.log({ e });
  }
};

export const getInfluencerHeatmap = async (coordinates) => {
  try {
    const state = store.getState();
    const selectedCampaign = campaignSelectors.selected(state);
    const geoJson = {
      type: 'GeometryCollection',
      geometries: [
        {
          type: 'Polygon',
          coordinates: [coordinates],
        },
      ],
    };

    console.log({ geoJson });
    console.log({ geojsonHeatMap: JSON.stringify(geoJson) });
    const { data } = await axios.get(`public/campaigns/${selectedCampaign._id}/geo-distribution`, { params: { geojson: geoJson } });
    console.log({ data });
    return data.data[0].distributions;
  } catch (e) {
    console.log({ campaignDetailError: e });
  }
};

export const checkPolygonsValidity = async (polygons) => {
  try {
    console.log({ polygons });

    // const geojson = {
    //   "type": "GeometryCollection",
    //   "geometries": [
    //     {
    //       "type": "Polygon",
    //       "coordinates": [
    //         // coordinates
    //       ]
    //     }
    //   ]
    // }
    const state = store.getState();
    const selectedCampaign = campaignSelectors.selected(state);
    const geoJson = getGeoJsonFromPolygons(polygons);
    console.log({ geoJson });
    console.log({ geojsonPolygons: JSON.stringify(geoJson) });
    const { data } = await axios.get(`public/campaigns/${selectedCampaign._id}/geo-distribution`, { params: { geojson: geoJson } });
    console.log({ POLYGONS_RES: data.data });
    return data.data[0];
  } catch (e) {
    console.log({ GeoDistributionError: e });
    return Promise.reject();
  }
};

export const getGeoJsonFromPolygons = (polygons) => {
  return {
    type: 'GeometryCollection',
    geometries: polygons.map((poly) => {
      return {
        type: 'Polygon',
        coordinates: [[...poly.path, poly.path[0]].map((coords) => [coords.lng, coords.lat])],
      };
    }),
  };
};

export const campaignChangeStatus = async (campaignId) => {
  try {
    const state = store.getState();
    const selectedBusinessUnit = businessUnitSelectors.selected(state);
    const params = {
      state: 'purchase',
      typeof: 'EnchoraUser',
    };
    const { data } = await axios.put(`private/business-units/${selectedBusinessUnit._id}/campaigns/${campaignId}/states`, 'purchase');
    console.log({ putStatusCampaign: data });
    toast(translate('campaign payment confirmed'), TOAST.SUCCESS);
  } catch (e) {
    toast(translate('campaign payment error'), TOAST.ERROR);
    console.log({ putStatusCampaignError: e });
  }
};

export const businessUnitResubmitCampaign = async (campaignId) => {
  try {
    // update, purchase, approve, decline, reject, submit, extend, start, close, stop, finalize
    const params = {
      state: 'submit',
      typeof: 'CampaignTriggerState',
    };
    const { data } = await axios.put(`private/enchorers/campaigns/${campaignId}/states`, params);
    console.log({ putStatusCampaign: data });
    store.dispatch(setCampaignSelected(data.data[0]));
    toast(translate('campaign submit success'), TOAST.SUCCESS);
    return data.data[0];
  } catch (e) {
    toast(translate('campaign submit error'), TOAST.ERROR);
    console.log({ putStatusCampaignError: e });
  }
};

export const postLanding = async (landingData) => {
  const state = store.getState();
  const campaign = campaignSelectors.selected(state);
  console.log({ landingData });
  try {
    const {
      data: { data },
    } = await axios.post(`private/untyped/${RESOURCES.LANDING}`, {});
    const landing = _.first(data);
    await patchLanding(landing._id, {
      ...landingData,
      campaignId: _.get(campaign, '_id', ''),
    });
    toast(translate('landing created success'), TOAST.SUCCESS);
  } catch (e) {
    toast(translate('landing created error'), TOAST.SUCCESS);
  }
};

export const getLanding = async (landingId) => {
  try {
    const {
      data: { data },
    } = await axios.get(`public/untyped/${RESOURCES.LANDING}/${landingId}`);
    return _.first(data);
  } catch (e) {
    return {};
  }
};

export const patchLanding = async (landingId, landingData) => {
  try {
    const updatedLanding = await uploadImagesLanding({
      ...landingData,
      _id: landingId,
    });
    console.log({ updatedLanding });

    const {
      data: { data },
    } = await axios.patch(`private/untyped/${RESOURCES.LANDING}/${landingId}`, {
      ...updatedLanding,
    });
    const landing = _.first(data);

    const advice = {
      external: false,
      url: {
        mimeType: 'text/uri-list',
        uri: `web.enchora.com/${PUBLIC}/campaigns/landing/${landing._id}`,
      },
      hash: landing._id,
    };

    store.dispatch(editCampaignSelected({ advice }));
    return data;
  } catch (e) {}
};

const uploadImagesLanding = async (initialLandingData) => {
  let landingData = initialLandingData;

  if (_.get(initialLandingData, 'background.toUpload', false)) {
    const background = await uploadImage(initialLandingData.background.uri, `untyped/${RESOURCES.LANDING}/${initialLandingData._id}/background`);
    landingData.background = _.cloneDeepWith(background);
  }

  if (_.get(initialLandingData, 'logo.toUpload', false)) {
    const logo = await uploadImage(initialLandingData.logo.uri, `untyped/${RESOURCES.LANDING}/${initialLandingData._id}/logo`);
    landingData.logo = _.cloneDeepWith(logo);
  }

  return { ...landingData };
};

export const fillSalesForm = async (formData, terms, cmpId, infId) => {
  try {
    const formattedData = {
      typeof: 'SaleForm',
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      vat: formData.vat,
      shippingAddress: formData.shippingAddress,
      quantity: Number(formData.quantity),
      phone: {
        typeof: 'phone',
        number: formData.number,
        prefix: formData.prefix,
      },
      ...terms,
    };

    console.log({ formattedData });
    const { data: response } = await axios.post(`public/fillSaleForm/${cmpId}/${infId}`, formattedData);
    console.log({ response });
    // toast(translate('product.fillSalesFormSuccess'), TOAST.SUCCESS);
    return response;
  } catch (e) {
    toast(translate('product.fillSalesFormFailed'), TOAST.ERROR);
    return false;
  }
};

export const getPublicPageCampaignInfo = async (cmpId) => {
  try {
    const { data } = await axios.get(`/public/form/campaign/${cmpId}`);
    console.log({ data });
    return {
      businessUnit: _.get(data, 'bun'),
      ..._.get(data, 'campaign'),
      availableQuantity: data.availableQuantity,
    };
  } catch (e) {
    toast(translate('product.publicPageError'), TOAST.ERROR);
    console.debug({ getPublicProductPageInfoError: e });
  }
};

export const getCampaignSales = async (cmpId) => {
  const params = {
    params: {
      params: {
        query: {
          'payment.status': 'COMPLETED',
        },
      },
    },
  };

  try {
    const { data } = await axios.get(`/private/getSales/${cmpId}`, params);
    return data;
  } catch (e) {
    console.debug({ e });
  }
};
