import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import Chart from 'react-apexcharts';
import { Col, Icon, InputRadio, Row } from '../../components/ui';
import { getCampaignKpi, getCampaignPrivate, getCatalogueAndSaveState, getMetricCampaign } from '../../api';
import { dateFromKey, dateFromKeyGantt, fromMillisToFormat, isActiveFrom, numberFormat, theme } from '../../utils';
import { metricSelectors } from '../../features/metricSlice';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './CampaignStatisticPage.scss';
import CampaignStatisticFilters from './CampaignStatisticFilters';
import { DateTime } from 'luxon';
import '../../components/layout/home/HomeHeader.scss';
import CampaignStatisticInfluencer from './CampaignStatisticInfluencer';
import { campaignTypes, CATALOGUES_TYPES } from '../../utils/const';
import { AppRoutes } from '../../app/routers';
import { HomeStatisticCard } from '../../components/layout/home/HomeHeader';

const CHART_TYPES = {
  BAR: 'bar',
  LINES: 'line',
};

const initialOptions = {
  chart: {
    type: CHART_TYPES.BAR,
    height: 400,
    stacked: false,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: false,
    },
  },
  series: [],
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 10,
      columnWidth: 20,
      barHeight: 30,
      dataLabels: {
        position: 'top',
        maxItems: 100,
        hideOverflowingLabels: true,
      },
    },
  },
  yaxis: {
    labels: {
      show: true,
      align: 'right',
      minWidth: 0,
      maxWidth: 160,
      style: {
        colors: [],
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-yaxis-label',
      },
      offsetX: 0,
      offsetY: 0,
      rotate: 0,
      formatter: (value) => {
        return value + '€';
      },
    },
  },
  xaxis: {
    type: 'string',
    tickAmount: undefined,
    tickPlacement: 'on',
    min: undefined,
    max: undefined,
    range: undefined,
    floating: false,
    crosshairs: {
      show: true,
      width: 1,
      position: 'back',
      opacity: 0.9,
      stroke: {
        color: '#b6b6b6',
        width: 0,
        dashArray: 0,
      },
      fill: {
        type: 'solid',
        color: '#B1B9C4',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
      dropShadow: {
        enabled: false,
        top: 0,
        left: 0,
        blur: 1,
        opacity: 0.4,
      },
    },
    categories: [],
  },
  legend: {
    position: 'left',
    offsetY: 40,
  },
  dataLabels: {
    enabled: false,
    distributed: true,
    style: {
      fontSize: '14px',
      fontFamily: 'Kanit, sans-serif',
      fontWeight: 'bold',
      colors: ['#000'],
    },
  },
  fill: {
    opacity: 0.8,
  },
};

export const STATISTICS_MODE = {
  PERFORMANCE: 'performance',
  COST: 'cost',
};

export const YAXIS = {
  [STATISTICS_MODE.PERFORMANCE]: {
    labels: {
      show: true,
      align: 'right',
      minWidth: 0,
      maxWidth: 160,
      style: {
        colors: [],
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-yaxis-label',
      },
      offsetX: 0,
      offsetY: 0,
      rotate: 0,
    },
  },
  [STATISTICS_MODE.COST]: {
    labels: {
      show: true,
      align: 'right',
      minWidth: 0,
      maxWidth: 160,
      style: {
        colors: [],
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-yaxis-label',
      },
      offsetX: 0,
      offsetY: 0,
      rotate: 0,
      formatter: (value) => {
        return value + '€';
      },
    },
  },
};

const CampaignStatisticPage = () => {
  let { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [options, setOptions] = useState({ ...initialOptions });
  const [campaign, setCampaign] = useState({});
  const [mode, setMode] = useState(STATISTICS_MODE.PERFORMANCE);
  const [kpi, setKpi] = useState({
    accepted: 0,
    average: 0,
    invited: 0,
    withPerformances: 0,
  });

  const campaignStatisticFilter = useSelector(metricSelectors.campaignStatisticFilter);
  const showSalesLink = campaign?.typeof === campaignTypes.PayPerSales && campaign?.performance > 0;
  console.log({ mode });
  useEffect(() => {
    getCatalogueAndSaveState(CATALOGUES_TYPES.LEVELS);
    getCampaignPrivate(id).then((res) => setCampaign(res));
    getCampaignKpi(id).then((res) => setKpi(res));
  }, []);

  useEffect(() => {
    updateMetrics(formattedDateFilters);
  }, [campaignStatisticFilter]);

  const formattedDateFilters = useMemo(() => {
    const from = _.get(campaignStatisticFilter, 'from') ? DateTime.fromMillis(Number(campaignStatisticFilter.from)).startOf('days').plus({ minutes: 1 }).toMillis() : undefined;
    const to = _.get(campaignStatisticFilter, 'to') ? DateTime.fromMillis(Number(campaignStatisticFilter.to)).endOf('days').toMillis() : undefined;

    return { from, to };
  }, [campaignStatisticFilter]);

  const updateOptionsSeries = (metricData, campaignRes, newMode = mode) => {
    console.debug({ metricData });
    setOptions((prevState) => {
      return _.cloneDeepWith({
        ...prevState,
        series: [
          {
            name: _.get(campaignRes, 'name'),
            data: _.map(metricData, (metric) => ({
              x: dateFromKeyGantt(metric.frame, metric.frameUnit),
              y: newMode === STATISTICS_MODE.COST ? metric.cost : metric.count,
            })),
          },
        ],
        yaxis: {
          ...YAXIS[newMode],
        },
      });
    });
  };

  const updateMetrics = async (filters, newMode) => {
    if (!campaign?._id) {
      return;
    }
    const metricData = await getMetricCampaign(campaign._id, filters);
    updateOptionsSeries(metricData, campaign, newMode);
  };

  const onChangeMode = async (newMode) => {
    setMode(newMode);
    await updateMetrics(formattedDateFilters, newMode);
  };

  const goToCampaignSales = (e) => {
    e.preventDefault();
    history.push(AppRoutes.campaignSales(campaign._id));
  };

  const salesLink = () => {
    return (
      <Col>
        <span className={'cmp-id-more'} onClick={goToCampaignSales}>
          {t('showSalesDetail')}
        </span>
      </Col>
    );
  };

  console.log({ campaign });
  const isTerminate = _.get(campaign, 'duration.end.ts') < DateTime.fromJSDate(new Date()).toMillis();
  return (
    <div id={'campaign-statistic'}>
      <CampaignDetailHeaderSection
        campaign={campaign}
        modeSlot={
          <Col md={3}>
            <Typography variant={'body2'} mb={'1rem'}>
              {t('campaign detail stats mode')}
            </Typography>
            <InputRadio label={t('campaign detail stats performance')} onChange={() => onChangeMode(STATISTICS_MODE.PERFORMANCE)} checked={mode === STATISTICS_MODE.PERFORMANCE} />
            <InputRadio label={t('campaign detail stats cost')} onChange={() => onChangeMode(STATISTICS_MODE.COST)} checked={mode === STATISTICS_MODE.COST} />
          </Col>
        }
        kpiSlot={
          <Row md={10} sm={12} flex grid spaceBetween style={{ minHeight: '11rem' }}>
            <HomeStatisticCard
              label={'accepted'}
              value={
                <span>
                  {kpi.accepted} ({(Number(kpi.accepted / kpi.invited) * 100).toFixed(0)}%)
                </span>
              }
              backgroundColor={theme['secondaryColor']}
            />
            <HomeStatisticCard label={'average'} value={numberFormat(kpi.average)} backgroundColor={theme['primaryColor']} />
            <HomeStatisticCard label={'invited'} value={kpi.invited} backgroundColor={theme['grey']} />
            <HomeStatisticCard
              label={'withPerformances'}
              value={
                <span>
                  {kpi.withPerformances} ({(Number(kpi.withPerformances / kpi.invited) * 100).toFixed(0)}%)
                </span>
              }
              backgroundColor={theme['primaryDarkColor']}
            />
          </Row>
        }
        salesLink={showSalesLink ? salesLink() : null}
      />
      <Row className={'section'}>
        <Icon icon={'far fa-calendar'} color={theme['black']} style={{ marginRight: '0.5rem' }} />
        {!isTerminate && (
          <div>
            <span>{t('your campaign is active by')}&nbsp;</span>
            <Typography variant="bold" color={theme['primaryColor']}>
              {isActiveFrom(campaign)}&nbsp;
            </Typography>
            <span>{t('days')}.&nbsp;</span>
            <span>{t('it ends the')}&nbsp;</span>
            <Typography variant="bold" color={theme['primaryColor']}>
              {fromMillisToFormat(_.get(campaign, 'duration.end.ts'))}&nbsp;
            </Typography>
          </div>
        )}
        {isTerminate && (
          <div>
            <span>{t('your campaign is terminated at')}&nbsp;</span>
            <Typography variant="bold" color={theme['primaryColor']}>
              {fromMillisToFormat(_.get(campaign, 'duration.end.ts'))}&nbsp;
            </Typography>
          </div>
        )}
      </Row>
      <CampaignStatisticFilters campaign={campaign} />
      <Chart series={options.series} options={options} type={CHART_TYPES.BAR} width={window.innerWidth - 100} height={400}>
        <div />
      </Chart>
      <CampaignStatisticInfluencer mode={mode} campaign={campaign} />
    </div>
  );
};
export default CampaignStatisticPage;

export const CampaignDetailHeaderSection = ({ campaign, modeSlot, salesLink, kpiSlot }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Row fullWidth spaceBetween>
        <Typography variant={'h4'} color={theme['primaryColor']}>
          <b>{t(`${campaign.typeof}.title`)}</b>
        </Typography>
      </Row>
      <Row md={12}>
        <Col md={modeSlot ? 9 : 12}>
          <Col grid container fullWidth flex spaceBetween style={{ marginTop: '1rem' }}>
            <Row flex item md={10}>
              <div className={'section'}>
                <Typography variant={'h5'}>
                  <b>{_.toUpper(_.get(campaign, 'name'))}</b>
                </Typography>
              </div>
              <div className={'section'}>
                <span>&nbsp;{t('created at')}&nbsp;</span>
                <span>{fromMillisToFormat(_.get(campaign, 'created.ts'))}</span>
              </div>
            </Row>
            <Row>{kpiSlot}</Row>
          </Col>
          <Row className={'section'}>
            <Icon icon={'far fa-coins'} color={theme['black']} style={{ marginRight: '0.5rem' }} />
            <div>
              <span>{t('you have')}&nbsp;</span>
              <Typography variant="bold" color={theme['primaryColor']}>
                {_.get(campaign, 'performance', 0)}&nbsp;
              </Typography>
              <span>{t(`${campaign?.typeof}.unit`).toLowerCase()}&nbsp;</span>
              <span>{t('from now').toLowerCase()}&nbsp;</span>
            </div>
          </Row>
        </Col>
        {modeSlot}
        {salesLink}
      </Row>
    </div>
  );
};
