import React, { useEffect, useMemo, useState } from 'react';
import './CampaignCartRow.scss';
import './../../../app/App.scss';
import { Button, Checkbox, Col, Icon, ProgressBar, Row } from '../../ui';
import { removeCampaignToCard, setCampaignCompletedSteps, setCampaignCurrentStep, setCampaignSelected } from '../../../features/campaignSlice';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { InfoDetailCampaign } from './CampaignDetail';
import { AppRoutes } from '../../../app/routers';
import { useHistory } from 'react-router-dom';
import {
  businessUnitResubmitCampaign,
  createCampaign,
  deleteCampaign,
  getBusinessUnitCampaigns,
  patchCampaign
} from '../../../api';
import { ConfirmModal } from '../modals';
import { useModal } from '../../../utils/hook';
import {
  BudgetCampaign,
  CampaignContent,
  CategoryAndTimeCampaign,
  LinkCampaign,
  SocialCampaign
} from './CampaignCardRecap';
import {
  campaignTypes,
  PROGRESS_BAR_COLOR,
  STEP_SOCIAL,
  campaignStatus as CMP_STATUSES,
  TOAST
} from '../../../utils/const';
import { numberFormat, showProgressBar, theme } from '../../../utils';
import { ChartIcon } from '../../../assets';
import { toast } from 'react-toastify';
import { translate } from '../../../i18n';
import { CampaignModalDismiss } from '../modals/CampaignModalExtend';

export const CampaignCartRow = ({ campaign, onClickCheckbox, detailPage, onOpenRejectedMessages, isAdmin, adminButtons }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [deleteModal, { open, close }] = useModal();
  const [extendModal, { open: openExtendModal, close: closeExtendModal }] = useModal();
  const [loading, setLoading] = useState(false);
  const creationDate = DateTime.fromMillis(campaign.created.ts).toFormat('dd/MM/yyyy');
  const total = _.get(campaign, 'budget.total.value', 0).toFixed(2);
  const dispatch = useDispatch();
  const campaignStatus = campaign.campaignStatus || '';
  const campaignType = _.get(campaign, 'typeof', '');
  const showStatisticButton = [CMP_STATUSES.ACTIVE, CMP_STATUSES.CLOSED, CMP_STATUSES.FINALIZED].includes(campaignStatus);
  const showSalesButton = [campaignTypes.PayPerSales].includes(campaignType);
  const rejectedMessage = _.get(campaign, 'rejectNotes', []);
  const showRejectedMessage = [CMP_STATUSES.PURCHASABLE, CMP_STATUSES.REJECTED].includes(campaignStatus) && !_.isEmpty(rejectedMessage);
  const disableEdit = ![CMP_STATUSES.DRAFT, CMP_STATUSES.PURCHASABLE].includes(campaignStatus);
  const showBuy = campaignStatus === CMP_STATUSES.PURCHASABLE;
  const showResubmit = campaignStatus === CMP_STATUSES.NOT_APPROVED;
  const showExtend = [CMP_STATUSES.ACTIVE].includes(campaignStatus);
  const showClone = [CMP_STATUSES.CLOSED, CMP_STATUSES.FINALIZED].includes(campaignStatus);
  const paymentPending = campaign.paymentStatus === 'pending';

  const removeToCart = async () => {
    setLoading(true);
    const { _id: id } = campaign;
    await deleteCampaign(id);
    setLoading(false);
    dispatch(removeCampaignToCard(id));
  };

  const goToEditCampaign = () => {
    dispatch(setCampaignSelected(campaign));
    dispatch(setCampaignCurrentStep(STEP_SOCIAL));
    dispatch(setCampaignCompletedSteps([]));
    history.push(`${AppRoutes.campaign(campaign?._id || 'new')}/${campaign?.typeof}`);
  };

  const goToCart = () => {
    history.push(AppRoutes.cart())
  };

  const onResubmit = async () => {
    await businessUnitResubmitCampaign(campaign._id)
  };

  const onRenew = async () => {
    const newCampaign = await createCampaign(campaign.type)
    const newData = {
      ...campaign,
      ...newCampaign,
      duration: {duration: 20}
    }
    await patchCampaign(newData)
    await getBusinessUnitCampaigns()
    toast(translate('campaign renew successfully'), TOAST.SUCCESS);
  };

  const style = { borderRadius: '1rem', minHeight: '8rem' };

  const StatusComponent = () => {
    return (
      <Col alignCenter justifyCenter className={`btn-campaign-status ${campaignStatus}`}>
        <Typography variant="bold">{t(campaignStatus).toUpperCase() || ' - '}</Typography>
      </Col>
    );
  };

  const goToCampaignStatistics = () => {
    history.push(AppRoutes.dashboardCampaignStatics(campaign._id));
  };

  const goToCampaignMessages = () => {
    onOpenRejectedMessages && onOpenRejectedMessages(campaign);
  };

  const toToCampaignSales = () => {
    history.push(AppRoutes.campaignSales(campaign._id));
  };
  const onExtend = () => {
    openExtendModal()
  };

  return (
    <div id={'campaign-cart-row'}>
      {showExtend && <CampaignModalDismiss open={extendModal} onClose={closeExtendModal} campaign={campaign}/>}
      <ConfirmModal open={deleteModal} onClose={close} title={'delete campaign'} subtitle={'sure to delete campaign'} textConfirm={'delete'} textUndo={'cancel'} onConfirm={removeToCart} />
      <Accordion expanded={isAdmin} TransitionProps={{ unmountOnExit: true }} md={12} style={{ width: '100%', borderRadius: '1rem' }} onClick={(e) => e.preventDefault()}>
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header" style={{ padding: '1rem' }}>
          <Col flex flexStart spaceBetween fullWidth>
            <Row alignEnd flex>
              <Row alignCenter xs={!!onClickCheckbox ? 11 : 12} mb={'1rem'}>
                <Row alignCenter spaceBetween mb={'0.5rem'}>
                  <Typography variant={'h3'}>{_.toUpper(campaign.name || 'no name')}</Typography>
                  <span className={'creation'}>
                    {t('created ad')} {creationDate}
                  </span>
                  {detailPage && (
                    <div style={{ display: 'flex', 'align-items': 'center' }}>
                      {showStatisticButton && showSalesButton && <Icon icon={'far fa-user'} color={theme['primaryColor']} size={'lg'} onClick={toToCampaignSales} alt={''} />}
                      {showStatisticButton && <img src={ChartIcon} className={'chart-icon'} onClick={goToCampaignStatistics} alt={''} style={{ marginLeft: '1rem' }} />}
                      {showRejectedMessage && <Icon icon={'far fa-envelope'} color={theme['primaryColor']} size={'lg'} onClick={goToCampaignMessages} />}
                      <StatusComponent />
                    </div>
                  )}
                </Row>
                <Row alignCenter spaceBetween>
                  <span className={'typeof'}>{campaign.typeof} {paymentPending && `- ${t('payment to verify')}`}</span>
                  {!!total && (
                    <span className={'typeof'}>
                      {t('total cost')}: {numberFormat(total)}€
                    </span>
                  )}
                </Row>
              </Row>
              {!!onClickCheckbox && (
                <Row flex justifyEnd xs={1} mb={'0.5rem'}>
                  <Checkbox checked={campaign.isSelected} name={'selected'} onClick={(e) => e.stopPropagation()} onChange={(e) => onClickCheckbox(campaign)} />
                </Row>
              )}
            </Row>
            {detailPage && showProgressBar(campaign) && (
              <Row xs={12}>
                <ProgressBar
                  progress={_.get(campaign, 'completionRate', 0) * 100}
                  bgColor={PROGRESS_BAR_COLOR[campaign.campaignStatus]}
                  endLabel={`${_.get(campaign, 'budget.quantity', 0)} ${t(campaign.typeof + '.unit')}`}
                />
              </Row>
            )}
          </Col>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '1rem' }}>
          <Col container flex fullWidth md={12} style={style}>
            <Col>
              <BudgetCampaign campaign={campaign} />
              <LinkCampaign campaign={campaign} />
              <SocialCampaign campaign={campaign} />
              {/*<InfoDetailCampaign fieldName={'brief'} valueClass={'brief-value'} value={campaign.brief} />*/}
              <CategoryAndTimeCampaign campaign={campaign} />
              <CampaignContent campaign={campaign}/>
              {/*{children}*/}
            </Col>
            {!isAdmin && <Row spaceAround md={12} style={{ marginTop: '1rem' }}>
              <Col md={3}>
                <Button variant={'outlined'} onClick={open} text={t('delete')} disabled={disableEdit} />
              </Col>
              <Col md={3}>
                <Button onClick={goToEditCampaign} text={t('edit')} disabled={disableEdit} />
              </Col>
              {showBuy && <Col md={3}>
                <Button onClick={goToCart} text={t('buy')} />
              </Col>}
              {/*{showResubmit && <Col md={3}>*/}
              {/*  <Button onClick={onResubmit} text={t('buy')} />*/}
              {/*</Col>}*/}
              {/*{showClone && <Col md={3}>*/}
              {/*  <Button onClick={onRenew} text={t('renew')} />*/}
              {/*</Col>}*/}
              {/*{showExtend && <Col md={3}>*/}
              {/*  <Button onClick={onExtend} text={t('extend')} />*/}
              {/*</Col>}*/}
            </Row>}
            {isAdmin && adminButtons}
          </Col>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
