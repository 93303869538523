import React, { useMemo, useState } from 'react';
import { BaseModal, Button, Checkbox, Col, InputRadio, Row } from '../../ui';
import { Box, Card, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './CampaignModalPayment.scss';
import { getCartTotal, openEnchoraTermsAndConditions } from '../../../utils';
import _ from 'lodash';
import { campaignChangeStatus, getBusinessUnitCampaigns } from '../../../api';
import { PAYMENT_TYPES } from '../createCompany/companyData4';
import { useSelector } from 'react-redux';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import PayPal from '../payments/PayPal';
import PayPalCampaign from '../payments/PayPalCampaign';
import { appSelectors } from '../../../features/appSlice';
import { ENVIRONMENTS } from '../../../app/App';
import StripeCampaign from '../payments/StripeCampaign';
import { createOrderBankTransferCampaign } from '../../../api/payments';

export const CampaignModalPayment = ({ open, onClose, selectedCampaigns, onConfirmPayment }) => {
  const { t } = useTranslation();
  const businessUnit = useSelector(businessUnitSelectors.selected);
  const [terms, setTerms] = useState(false);
  const env = useSelector(appSelectors.localEnvironment);
  // const [payment, setPayment] = useState(_.get(businessUnit, 'payments.default', ''));
  const [payment, setPayment] = useState(PAYMENT_TYPES.BANK_TRANSFER);


  const selectedCampaignIds = selectedCampaigns.map((cmp) => cmp._id);
  console.log({ selectedCampaignIds });

  const totalCost = useMemo(() => {
    return getCartTotal(selectedCampaigns);
  }, [selectedCampaigns]);

  const onClickConfirm = async () => {
    if (payment === PAYMENT_TYPES.BANK_TRANSFER) {
      // _.forEach(selectedCampaigns, async (camp) => {
        // await campaignChangeStatus(camp._id);
      const total =  _.reduce(
        selectedCampaigns,
        (sum, campaign) => {
          return sum + _.get(campaign, 'budget.total.value');
        },
        0
      );

        const stripeOrder = {
          "typeof": "PaymentIn",
          "provider": "bank-account",
          "note": "",
          "correlationId": selectedCampaignIds,
          amount: {
            currency: selectedCampaigns[0].budget.total.currency,
            value: total
          },
          req: {}
        }

        await createOrderBankTransferCampaign(stripeOrder)
      // });
      onConfirmPayment();
      setTimeout(() => {
        getBusinessUnitCampaigns();
      }, 300);
      onClose();
    }
  };

  const onPayApprove = () => {
    onConfirmPayment();
    setTimeout(() => {
      getBusinessUnitCampaigns();
    }, 300);
    onClose();
  };

  const onPressCheck = (pressed) => {
    setPayment(pressed);
  };

  console.log({ businessUnit });


  return (
    <BaseModal open={open} onClose={onClose}>
      <Card id={'campaign-modal-payment'}>
        <Typography variant="h2">{t('payment')}</Typography>
        <Col>
          <Row>
            {/*<span>{t('campaign')}</span>*/}
            {/*<Typography variant={'bold'} color={theme['primaryColor']} style={{marginLeft: '1rem'}}>{t(campaign.typeof)}</Typography>*/}
          </Row>
          <Row>{/*<span>{t(campaign.name)}</span>*/}</Row>
          <hr style={{ margin: '1px' }} />
          <Row spaceBetween style={{ marginTop: '1rem' }}>
            <Typography variant={'h3'}>
              {t('total cost')} {totalCost.toFixed(2)}€
            </Typography>
            {/*<Typography variant={'h3'}>{normalizeCurrency(campaign.budget.budget)}</Typography>*/}
          </Row>
          <Box p="0px" mt="0.5rem">
            <Typography variant="body2">{t('disclaimer payment modal')}</Typography>
          </Box>

          <Col xs={11} md={8} mt={'1rem'} mb={'1rem'}>
            <Typography variant={'subtitle'}>{t('payment method')}</Typography>
            <Typography variant={'body2'} mb={'1rem'}>
              {t('payment method nex time')}
            </Typography>
            <InputRadio
              label={t('credit card')}
              onChange={() => onPressCheck(PAYMENT_TYPES.CREDIT_CARD)}
              checked={payment === PAYMENT_TYPES.CREDIT_CARD}
              disabled={env !== ENVIRONMENTS.DEV && env !== ENVIRONMENTS.PRE_PROD}
            />
            <InputRadio
              label={t('paypal')}
              onChange={() => onPressCheck(PAYMENT_TYPES.PAYPAL)}
              checked={payment === PAYMENT_TYPES.PAYPAL}
              disabled={env !== ENVIRONMENTS.DEV && env !== ENVIRONMENTS.PRE_PROD}
            />
            <InputRadio label={t('bank transfer')} onChange={() => onPressCheck(PAYMENT_TYPES.BANK_TRANSFER)} checked={payment === PAYMENT_TYPES.BANK_TRANSFER} />
          </Col>

          <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
            <PrivacySection terms={terms} setTerms={setTerms} />
          </div>
          <Row spaceBetween>
            <Col md={5}>
              <Button text={t('cancel')} fullWidth onClick={onClose} variant={'outlined'} />
            </Col>
            <Col md={5}>
              {payment === PAYMENT_TYPES.CREDIT_CARD && <StripeCampaign onPayApprove={onPayApprove} total={totalCost} customIds={selectedCampaignIds} disabled={!terms} />}
              {payment === PAYMENT_TYPES.PAYPAL && <PayPalCampaign onPayApprove={onPayApprove} total={totalCost} customIds={selectedCampaignIds} />}
              {payment !== PAYMENT_TYPES.PAYPAL && payment !== PAYMENT_TYPES.CREDIT_CARD && <Button text={t('buy')} fullWidth onClick={onClickConfirm} disabled={!terms} />}
            </Col>
          </Row>
        </Col>
      </Card>
    </BaseModal>
  );
};

export const PrivacySection = ({ terms, setTerms }) => {
  const { t } = useTranslation();
  return (
    <Col>
      <span className={'privacy-tip'}>{t('cartPrivacyTip')}</span>
      <Row alignCenter grid fullWidth md={12} sm={12} xs={12}>
        <Col noFlex alignCenter className={'checkbox-container-modal'}>
          <Checkbox checked={terms} onChange={() => setTerms((prev) => !prev)} />
        </Col>
        <Row style={{ width: '85%' }}>
          <span className={'privacyText'}>&nbsp;{t('cartAccept1')}&nbsp;</span>
          <span className={'privacyLink'} onClick={openEnchoraTermsAndConditions}>
            {t('cardEnchoraTerms')}
          </span>
          <span className={'privacyText'}>&nbsp;{t('cartAccept2')}&nbsp;</span>
        </Row>
      </Row>
    </Col>
  );
};
