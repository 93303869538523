import React, { memo, useEffect, useState } from 'react';
import './HomePerformances.scss';
import { Col } from '../../ui';
import _ from 'lodash';
import { campaignTypes, FRAME_TYPE } from '../../../utils/const';
import Chart from 'react-apexcharts';
import { getMetricsHistogramHome } from '../../../api';
import HomePerformanceFilters from './HomePerformanceFilters';
import { DateTime } from 'luxon';
import { dateFromKeyGantt, ganttScaleUnitBasedOnFilters, getFromToDatePerformanceIfNoFilters, normalizeHomePerformance, numberFormat } from '../../../utils';
import { useSelector } from 'react-redux';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import { useTranslation } from 'react-i18next';

export const campaignTypeOpt = {
  ...campaignTypes,
  allTypes: undefined,
};

const colors = {
  [campaignTypes.PayPerClick]: '#ffe293',
  [campaignTypes.PayPerPostEngagement]: '#ffd35d',
  [campaignTypes.PayPerSales]: '#ff9627',
  [campaignTypes.PayPerContact]: '#ff6427',
};

const chartOptions = {
  chart: {
    type: 'bar',
    height: 400,
    stacked: true,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  colors: [..._.values(colors)],
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
        },
      },
    },
  ],
  series: [],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 5,
      dataLabels: {
        position: 'bottom',
      },
    },
  },
  yaxis: {
    labels: {
      show: true,
      align: 'right',
      minWidth: 0,
      maxWidth: 160,
      style: {
        colors: [],
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-yaxis-label',
      },
      offsetX: 0,
      offsetY: 0,
      rotate: 0,
      formatter: (value) => {
        return value + '€';
      },
    },
  },
  legend: {
    position: 'left',
    offsetY: 40,
  },
  dataLabels: {
    enabled: false,
    distributed: false,
    style: {
      fontSize: '14px',
      fontFamily: 'Kanit, sans-serif',
      fontWeight: 'bold',
      colors: ['#000'],
    },
  },
  fill: {
    opacity: 0.8,
  },
};

const HomePerformances = () => {
  const { t } = useTranslation();
  const selectedBusinessUnit = useSelector(businessUnitSelectors.selected);

  const [filters, setFilters] = useState({
    from: DateTime.now().minus({ days: 13 }).toMillis(),
    to: DateTime.now().toMillis(),
    campaignStatus: [],
  });
  const [options, setOptions] = useState(chartOptions);

  useEffect(() => {
    getData(filters);
  }, [filters, selectedBusinessUnit]);

  const updateOptionsSeries = (newSeries) => {
    console.debug({ newSeries });
    setOptions((prevState) => {
      return _.cloneDeepWith({
        ...prevState,
        series: [...newSeries],
      });
    });
  };

  const getData = async (filters) => {
    const metricData = await getMetricsHistogramHome(filters);
    let units = { apiUnit: FRAME_TYPE.DAILY, unit: 'days' };
    const newSeries = _.reduce(
      metricData,
      (result, cmpMetricData) => {
        // console.debug({ result, cmpMetricData });
        // const fromDate = DateTime.now().minus({ days: 12 });
        // const toDate = DateTime.now().plus({ days: 1 });
        let fromDate = filters.from ? DateTime.fromMillis(filters.from) : null;
        let toDate = filters.to ? DateTime.fromMillis(filters.to).plus({ days: 1 }) : null;
        let metricDataNormalized = cmpMetricData.data;

        if (!fromDate || !toDate) {
          const res = getFromToDatePerformanceIfNoFilters(metricData, fromDate, toDate);
          fromDate = res.fromDate;
          toDate = res.toDate;

          units = { apiUnit: FRAME_TYPE.WEEKLY, unit: 'weeks' };
          metricDataNormalized = _.map(cmpMetricData.data, (acc) => {
            const [days, weeks, quarters, months, years] = _.split(acc.frame, ':');
            let frame;
            if (units.apiUnit === FRAME_TYPE.WEEKLY) {
              frame = `*:${weeks}:${quarters}:${months}:${years}`;
            }

            return {
              ...acc,
              frame,
            };
          });
        }

        const normalizedDate = normalizeHomePerformance(units, metricDataNormalized, fromDate, toDate);
        console.debug({ normalizedDate });
        // const emptyData = emptyDataFromDate(fromDate, toDate, FRAME_TYPE.DAILY);
        // console.debug({ normalizedDate, emptyData });
        const singleData = {
          name: cmpMetricData.name,
          data: _.map(normalizedDate, (singleData) => ({
            x: dateFromKeyGantt(singleData.frame, units),
            y: numberFormat(singleData.value),
          })),
        };
        result.push(singleData);
        return result;
      },
      []
    );

    updateOptionsSeries(newSeries);
  };

  return (
    <Col flex id={'home-performances'}>
      <HomePerformanceFilters filters={filters} setFilters={setFilters} />
      {!_.isEmpty(options.series) ? (
        <Chart series={options.series} options={options} type="bar" width={window.innerWidth - 100} height={400}>
          <div />
        </Chart>
      ) : (
        <Col>
          <span>{t('noAvailableData')}</span>
        </Col>
      )}
    </Col>
  );
};

export default memo(HomePerformances);
