import React, { useEffect, useState } from 'react';
import './CampaignDetailPage.scss';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { campaignSelectors } from '../../../features/campaignSlice';
import { deleteCampaign } from '../../../api';
import { Button, Col, Icon, Row } from '../../../components/ui';
import {
  enchorerApproveCampaign, enchorerApprovePaymentCampaign,
  enchorerDeclineCampaign,
  enchorerDismissCampaign, enchorerFinalizeCampaign,
  getEnchorerCampaignDetail
} from '../../../api/enchorer';
import { CampaignModalDismiss, ConfirmModal } from '../../../components/layout/modals';
import _ from 'lodash';
import { IconVkBlack } from '../../../assets';
import { campaignStatus, TOAST } from '../../../utils/const';
import { dismissMode } from '../../../components/layout/modals/CampaignModalDismiss';
import { toast } from 'react-toastify';
import { translate } from '../../../i18n';
import clsx from 'clsx';
import { CampaignDetail } from '../../../components/layout/campaign/CampaignDetail';
import eventBus, { EVENT_BUS_ACTION } from '../../../utils/eventBus';
import { CampaignCartRow } from '../../../components/layout/campaign/CampaignCartRow';
import { Typography } from '@mui/material';

const CampaignDetailPage = ({}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const campaignSelected = useSelector(campaignSelectors.selected);
  const { budget, brief } = campaignSelected;
  const socials = _.get(campaignSelected, 'contents.influencerContent.socials') || [];
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showApprovePaymentModal, setShowApprovePaymentModal] = useState(false);
  const [showFinalizePaymentModal, setShowFinalizePaymentModal] = useState(false);
  const [showDismissModal, setShowDismissModal] = useState(false);
  const status = _.get(campaignSelected, 'campaignStatus', '');
  const showReject = status === campaignStatus.TO_BE_APPROVED
  const showRefuse = status === campaignStatus.TO_BE_APPROVED
  const showApprove = status === campaignStatus.TO_BE_APPROVED
  const showFinalize = status === campaignStatus.CLOSED
  console.log({ socials, campaignSelected });
  let { id } = useParams();
  console.log({ id });

  useEffect(() => {
    if (id) {
      getEnchorerCampaignDetail(id);
    }
  }, []);

  const onShowDismissModal = () => setShowDismissModal(true);
  const onCloseDismissModal = () => setShowDismissModal(false);
  const onShowApproveModal = () => setShowApproveModal(true);
  const onCloseApproveModal = () => setShowApproveModal(false);
  const onShowApprovePaymentModal = () => setShowApprovePaymentModal(true);
  const onCloseApprovePaymentModal = () => setShowApprovePaymentModal(false);
  const onShowFinalizePaymentModal = () => setShowFinalizePaymentModal(true);
  const onCloseFinalizePaymentModal = () => setShowFinalizePaymentModal(false);

  const onApproveCampaign = async () => {
    // await enchorerFinalizeCampaign(campaignSelected._id);
    await enchorerApproveCampaign(campaignSelected._id);
    eventBus.$emit(EVENT_BUS_ACTION.ITEM_CHANGED, null);
  };

  const onApprovePayment = async () => {
    await enchorerApprovePaymentCampaign(campaignSelected._id, campaignSelected.budget.total);
  };

  const onFinalizePayment = async () => {
    await enchorerFinalizeCampaign(campaignSelected._id);
  };

  const onDismissCampaign = async (mode, notes) => {
    console.log({ mode, notes });
    try {
      if (mode === dismissMode.DELETE) {
        await enchorerDismissCampaign(campaignSelected._id, notes);
        // await deleteCampaign(campaignSelected._id);
        // eventBus.$emit(EVENT_BUS_ACTION.ITEM_DELETED, null);
        // history.goBack();
        // toast(translate('campaign delete success'), TOAST.SUCCESS);
        eventBus.$emit(EVENT_BUS_ACTION.ITEM_CHANGED, null);
      } else {
        await enchorerDeclineCampaign(campaignSelected._id)
        eventBus.$emit(EVENT_BUS_ACTION.ITEM_CHANGED, null);
      }
    } catch (e) {
      toast(translate(`campaign ${mode} error`), TOAST.SUCCESS);
    }
  };

  const onGoBack = () => {
    history.goBack();
  };

  const onReject = async () => {
    await enchorerDeclineCampaign(campaignSelected._id)
  };

  const Action = () => {
    const campaignSelected = useSelector(campaignSelectors.selected);
    console.log({campaignSelected})
    const showApprovePayment = _.get(campaignSelected, 'paymentStatus') === 'pending'

    return (
      <div>
        {showApprovePayment && <Col>
          <Typography variant="h3" mb={'0.5rem'}>{t('payment in pending')}</Typography>
          <Typography variant="body2" mb={'1rem'}>{t('this campaign is payed by bank transfer')}</Typography>
          <Button text={t('approve payment')} fullWidth onClick={onShowApprovePaymentModal}/>

        </Col>}
        <Row spaceBetween className={'action-container'}>
          <Col flex>
            <Button variant={'outlined'} fullWidth text={t('go back')} onClick={onGoBack} />
          </Col>
          {/* <Col flex className={'button-bottom'}>*/}
          {/*   {showReject && <Button variant={'outlined'} fullWidth text={t('decline')} onClick={onReject} />}*/}
          {/*</Col>*/}
          <Col flex className={'button-bottom'}>
            {showRefuse && (
              <Button variant={'outlined'} fullWidth text={t('dismiss')}  onClick={onShowDismissModal} />
            )}
          </Col>
          <Col flex>
            {showApprove && (
              <Button text={t('approve')} fullWidth onClick={onShowApproveModal} disabled={showApprovePayment}/>
            )}
            {/*{showFinalize && (*/}
            {/*  <Button text={t('finalize')} fullWidth onClick={onShowFinalizePaymentModal}/>*/}
            {/*)}*/}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div id={'campaign-detail-page'}>
      <CampaignModalDismiss open={showDismissModal} onClose={onCloseDismissModal} onConfirm={onDismissCampaign} />
      <ConfirmModal title={t('approve campaign')} subtitle={t('sure to approve campaign')} open={showApproveModal} onClose={onCloseApproveModal} onConfirm={onApproveCampaign} />
      <ConfirmModal title={t('approve payment campaign')} subtitle={t('sure to approve campaign payment')} open={showApprovePaymentModal} onClose={onCloseApprovePaymentModal} onConfirm={onApprovePayment} />
      <ConfirmModal title={t('finalize campaign')} subtitle={t('sure to finalize campaign')} open={showFinalizePaymentModal} onClose={onCloseFinalizePaymentModal} onConfirm={onFinalizePayment} />
      <CampaignDetail action={<Action />} />
    </div>
  );
};

export default CampaignDetailPage;

export const ListSocial = ({ socials, className, sx = { height: 15, width: 15 }, iconStyle, showSeparator, rowStyle }) => {
  //check su campagne vecchie che hanno socials come id
  if (_.isString(socials[0])) {
    return <Col />;
  }
  return (
    <Col>
      {showSeparator && <hr />}
      <Row className={'social-container'} style={rowStyle}>
        {socials &&
          socials?.map((social) =>
            social.label === 'VKontakte' ? (
              <div style={sx}>
                {' '}
                <img src={IconVkBlack} alt={social._id} style={iconStyle} />
              </div>
            ) : (
              <Icon icon={`fab ${social.values[1]}`} sx={sx} className={className} style={iconStyle} />
            )
          )}
      </Row>
    </Col>
  );
};

export const InfoDetailCampaign = ({ fieldName, value, icon, className, unit, style }) => {
  const { t } = useTranslation();
  return (
    <Row container spaceBetween className={'info-container'}>
      <div style={{ width: '1.5rem' }}>{icon && <Icon icon={icon} />}</div>
      <Col flexStart flex>
        <div className={clsx(className)}>{t(fieldName)}</div>
      </Col>
      <Col flexEnd>
        <span className={className} style={style}>
          {value}
          {!!unit && unit}
        </span>
      </Col>
    </Row>
  );
};
